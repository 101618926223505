// src/utils/convertDurationToMinutes.js

// export const convertDurationToMinutes = (duration) => {
//   const hours = parseInt(duration.match(/(\d+)h/)?.[1] || "0", 10);
//   const minutes = parseInt(duration.match(/(\d+)m/)?.[1] || "0", 10);
//   return hours * 60 + minutes;
// };

export const convertDurationToMinutes = (duration) => {
  // duration が null または undefined の場合に備えたデフォルト値を設定
  if (!duration || typeof duration !== "string") {
    console.error("Invalid duration:", duration);
    return 0; // 無効な場合は 0 分にする
  }

  // 時間と分を分割して整数に変換
  const [hoursStr, minutesStr] = duration.split(":");

  // 時間と分がNaNでないかを確認し、数値に変換
  const hours = isNaN(parseInt(hoursStr, 10)) ? 0 : parseInt(hoursStr, 10);
  const minutes = isNaN(parseInt(minutesStr, 10))
    ? 0
    : parseInt(minutesStr, 10);

  // もし時間または分が無効な場合（NaN）に備えて、0 にフォールバック
  if (isNaN(hours) || isNaN(minutes)) {
    console.error("Invalid time format:", duration);
    return 0;
  }

  return hours * 60 + minutes;
};
