import { useContext, useEffect, useRef, useState } from "react";
import "./Management.scss";
import { MemberContext } from "../Contexts/MemberContext";
import { useLocation, useNavigate } from "react-router-dom";
import { se } from "date-fns/locale";
import { EventContext } from "../Contexts/EventContext";
import { EventMembersContext } from "../Contexts/EventMembersContext";
import { db } from "../firebase";
import { getFirestore, collection, addDoc } from "firebase/firestore";

function Management() {
  const defaultTo = "matsumo33333@gmail.com";
  const defaultSubject = "バックアップデータ";
  const defaultText = "データベースのバックアップです。";

  const [to, setTo] = useState(defaultTo);
  const [subject, setSubject] = useState(defaultSubject);
  const [text, setText] = useState(defaultText);
  const [responseMessage, setResponseMessage] = useState("");
  const { membersList, currentUserInfo } = useContext(MemberContext);
  const [events, setEvents] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [eventMembers, setEventMembers] = useState([]);
  const { eventList } = useContext(EventContext);
  const { eventMembersList } = useContext(EventMembersContext);
  const navigate = useNavigate();
  // バックアップデータを整形

  //管理者認証者のみ閲覧するよう設定
  useEffect(() => {
    // const isCurrentUserAdmin = currentUserInfo?.administrator === true;
    console.log(currentUserInfo);
    if (currentUserInfo?.administrator) {
      console.log("カレントユーザ情報を取得しました");
    } else {
      console.log("あかんで");
      navigate("/login");
      return;
    }
  }, []);

  const createBackupData = () => {
    // eventList 内の duration を文字列として処理
    const processedEventList = eventList.map((event) => ({
      ...event,
      duration: String(event.duration).padStart(2, "0"), // duration を文字列としてフォーマット
    }));

    return {
      events: JSON.stringify(processedEventList), // 処理したイベントリストを JSON 文字列として保存
      eventMembers: JSON.stringify(eventMembersList),
      members: JSON.stringify(membersList),
    };
  };

  const handleSendBackup = async () => {
    const sendData = createBackupData();
    console.log(sendData);
    try {
      const response = await fetch(
        "https://us-central1-raising2024-7e234.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to,
            subject,
            text,
            sendData: JSON.stringify(sendData, null, 2),
          }),
        }
      );
      if (!response.ok) {
        throw new Error("バックアップメール送信に失敗しました");
      }
      const data = await response.json();
      setResponseMessage(data.message);
    } catch (error) {
      console.error("エラー:", error);
      setResponseMessage("バックアップメール送信中にエラーが発生しました。");
    }
  };

  // すべてのinput, textareaを格納するref
  const inputRefs = [useRef(null), useRef(null), useRef(null)];

  const handleMouseEnter = (index) => {
    if (inputRefs[index].current) {
      inputRefs[index].current.focus();
    }
  };

  // Enterキーで次の入力欄にフォーカスを移動
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputRefs[index + 1]?.current) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleEventcreate = () => {
    navigate(`/eventinfo/`);
  };
  const handleEventSelect = () => {
    navigate(`/eventselect`);
  };
  const handleCalendar = () => {
    navigate(`/calendar`);
  };
  const handlePaticiant = () => {
    navigate(`/memberinfo/create`);
  };

  const handleBackUpReUse = () => {
    const db = getFirestore();
    //文字列からJSONに変換
    const eventsData = JSON.parse(
      //   '[{"id":"7dFoHgE3FStnxJ9cOZEf","site_region":"京都府","map":"https://maps.app.goo.gl/EQDG5Ydjp7Xdc4eS9","author":{"id":"OoTiIsPzWUWYUa9qhG186evKhph2","username":"秀正マツトモ"},"rank":"","updated_at":"2025-01-15T06:27:42.101Z","starttime":"2025-02-25T16:00:00.000Z","duration_minute":"00","starttime_hour":"01","court_surface":"","duration_hour":"03","duration":"00","court_num":" ","deadline_hours_before":"11","deadlinedate":"2025-02-25T05:00:00.000Z","tennis_off":true,"detail":"","court_count":"1","title":"水曜ナイター","starttime_date":"2025-02-26","starttime_minute":"00","site":"北部コミセン","capacity":"6","created_at":"2025-01-14T15:23:23.650Z","endtime":""},{"id":"CRpBC7YrTiBYxvwJfQ3R","title":"水曜テニス","site":"城陽市鴻巣運動公園","site_region":"京都府","starttime_date":"2025-02-05","starttime_hour":"06","starttime_minute":"00","duration_hour":"03","duration_minute":"00","endtime":"","court_surface":"","court_count":"1","court_num":" ","capacity":"6","map":"https://maps.app.goo.gl/EQDG5Ydjp7Xdc4eS9","detail":"","rank":"","created_at":"2025-01-15T08:46:59.809Z","updated_at":"2025-01-15T08:46:59.809Z","deadline_hours_before":"7","starttime":"2025-02-04T21:00:00.000Z","duration":"00","deadlinedate":"2025-02-04T14:00:00.000Z","author":{"username":"秀正マツトモ","id":"OoTiIsPzWUWYUa9qhG186evKhph2"}}]'
      // );
      '[{"id":"BNqmeyEYHLJhsXB7HGWT","duration_minute":"00","starttime_hour":"01","rank":"","tennis_off":false,"created_at":"2025-01-16T00:47:13.640Z","court_count":"1","author":{"id":"OoTiIsPzWUWYUa9qhG186evKhph2","username":"秀正マツトモ"},"deadline_hours_before":"11","detail":"","starttime":"2025-02-25T16:00:00.000Z","court_surface":"","updated_at":"2025-01-16T00:47:30.155Z","site_region":"京都府","map":"https://maps.app.goo.gl/EQDG5Ydjp7Xdc4eS9","duration":"00","endtime":"","capacity":"6","court_num":" ","starttime_date":"2025-02-26","duration_hour":"03","site":"北部コミセン","title":"水曜ナイター再利用","starttime_minute":"00","deadlinedate":"2025-02-25T05:00:00.000Z"}]'
    );
    const formattedEvents = eventsData.map((event) => {
      const formattedStarttime = `${event.starttime_date}T${event.starttime_hour}:${event.starttime_minute}:00+09:00`;

      // durationをフォーマット
      const formattedDuration = `${event.duration_hour.padStart(
        2,
        "0"
      )}:${event.duration_minute.padStart(2, "0")}`;
      return {
        ...event,
        starttime: formattedStarttime,
        duration: formattedDuration,
      };
    });

    console.log(eventsData);

    const eventMembersData = JSON.parse(
      '[{"id":"79dZsRrFcKsRpg3hrXNF", "accountname":"近視政宗２"}]'
    );
    const membersData = JSON.parse(
      '[{"id":"2wPtoyZa7XTpseCO6Hmj","author":{"id":"0iKJ66bN0YQTGjxa796zKOGphi13","username":"名無し"},"rank":"近視政宗","profile":"","birthday":"","admin":true,"photo":"","accountname":"近視政宗２","administrator":false,"email":"matsumo33333@gmail.com","tel_num":"0774555532","realname":"松本　正"},{"id":"BtDVN1KjgQR2wE1mBYd3","author":{"username":null,"id":"CmN1GdaQIcQAMWoxeMZL540oc502"},"email":"matsumo33333@gmail.com","photo":"","admin":true,"tel_num":"0774555532","accountname":"近","profile":"","realname":"松本　正","administrator":false,"birthday":"","rank":"近視政宗"},{"id":"LXAHX03mvYpTXXX2b67N","rank":"","realname":"マツモトタダシ","email":"matsumo33333@gmail.com","administrator":true,"photo":"","tel_num":"09037214456","accountname":"管理人","admin":true,"author":{"id":"OoTiIsPzWUWYUa9qhG186evKhph2","username":"秀正マツトモ"},"birthday":"","profile":""},{"id":"VRtnra4Wyq9nmiJgwgEb","email":"","profile":"","administrator":false,"realname":"","rank":"","accountname":"テニスオフ","tel_num":"","birthday":"","photo":"","author":{"id":"tennisoff-nJan12202521:39:34","username":"秀正マツトモ"},"admin":false},{"id":"bJseqnwcrrzaRnysNSSZ","admin":true,"realname":"松本　正","administrator":false,"rank":"近視政宗","accountname":"グラン不","tel_num":"0774555532","author":{"username":null,"id":"pqauXrYPRYMnAC57BpFogxcEAmJ2"},"photo":"","profile":"","email":"matsumo333@icloud.com","birthday":""},{"id":"wUTzOe6z8uPNClLjSo8M","profile":"","email":"","rank":"","admin":false,"administrator":false,"photo":"","birthday":"","accountname":"テニスオフ","author":{"id":"tennisoff-nJan12202521:40:02","username":"秀正マツトモ"},"tel_num":"","realname":""}]'
    );

    // Firestoreコレクションにデータを追加
    const addEvents = async () => {
      const eventsCollectionRef = collection(db, "events");
      formattedEvents.forEach(async (event) => {
        await addDoc(eventsCollectionRef, event); // イベントをFirestoreに追加
      });
    };

    const addEventMembers = async () => {
      const eventMembersCollectionRef = collection(db, "event_members"); // collection関数を使用
      eventMembersData.forEach(async (eventMember) => {
        await addDoc(eventMembersCollectionRef, eventMember); // addDocを使ってデータを追加
      });
    };

    const addMembers = async () => {
      const membersCollectionRef = collection(db, "members");
      membersData.forEach(async (member) => {
        await addDoc(membersCollectionRef, member); // メンバーをFirestoreに追加
      });
    };

    // 各関数を呼び出してデータを追加
    addEvents();
    // addEventMembers();
    // addMembers();
  };

  return (
    <div className="management-container">
      <div className="management-content">
        <div className="management-title">データベースバックアップ</div>
        <form
          className="management-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSendBackup();
          }}
        >
          <div className="management-field">
            <label onMouseEnter={() => handleMouseEnter(0)}>
              宛先メールアドレス:
            </label>
            <input
              ref={inputRefs[0]}
              className="management-input"
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              placeholder="例: email1@example.com, email2@example.com"
              required
              onMouseEnter={() => handleMouseEnter(0)}
              onKeyDown={(e) => handleKeyDown(e, 0)}
            />
          </div>

          <div className="management-field">
            <label
              className="management-label"
              onMouseEnter={() => handleMouseEnter(1)}
            >
              件名:
            </label>
            <input
              ref={inputRefs[1]}
              className="management-input"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              onMouseEnter={() => handleMouseEnter(1)}
              onKeyDown={(e) => handleKeyDown(e, 1)}
            />
          </div>

          <div className="management-field">
            <label
              className="management-label"
              onMouseEnter={() => handleMouseEnter(2)}
            >
              本文:
            </label>
            <textarea
              ref={inputRefs[2]}
              className="management-textarea"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
              onMouseEnter={() => handleMouseEnter(2)}
            />
          </div>

          <button className="management-button" type="submit">
            バックアップメール送信
          </button>
        </form>

        {responseMessage && (
          <h2 className="management-response">{responseMessage}</h2>
        )}
      </div>
      <div className="management-content">
        <div className="management-field">
          <button className="management-button" onClick={handleEventSelect}>
            日程再利用{" "}
          </button>
          <p>イベントの登録を過去の情報を元に作成します。</p>
        </div>
        <div className="management-field">
          <button className="management-button" onClick={handleEventcreate}>
            新規日程入力{" "}
          </button>
          <p>イベントの登録を何もない状態で行います。</p>
        </div>
      </div>
      <div className="management-content">
        <div className="management-field">
          <button className="management-button" onClick={handlePaticiant}>
            会員外の方の名前追加{" "}
          </button>
          <p>
            会員以外の方が参加される場合、お名前をシステムに登録してから、それぞれのイベントの詳細ページで参加ボタンを押してください。
          </p>
        </div>
        {/* // イベント再利用ハンドラー
  const handleCreateEvent = (eventId) => {
    navigate(`/eventinfo/${eventId}/eventreuse`);
  }; */}
      </div>
      <div className="management-content">
        <div className="management-field">
          <button
            className="management-button"
            onClick={() => {
              navigate(`/backupimport`);
            }}
          >
            バックアップデータ復元{" "}
          </button>
        </div>{" "}
        <div className="management-field">
          <p>
            バックアップの復元の際、firebaseのデータをアウトプットするときに、duretionのデータの文字列の00が数字に変換されてしまうことがる
            これを取り込むと表示の際、文字と数字が混在することで表示エラーが発生する。
            また、startdateについても分解したことで、00があると数字になる、こうしたエラーが生じないようインポートの際には工夫が必要だと思われる
          </p>
        </div>{" "}
      </div>
      <div className="management-content">
        {" "}
        <div className="management-field">
          <button
            className="management-button"
            onClick={() => navigate(`/sentemails`)}
          >
            メールバックアップの確認{" "}
          </button>
        </div>{" "}
        <div className="management-field">
          <p>
            バックアップの復元の際、firebaseのデータをアウトプットするときに、duretionのデータの文字列の00が数字に変換されてしまうことがる
            これを取り込むと表示の際、文字と数字が混在することで表示エラーが発生する。
            また、startdateについても分解したことで、00があると数字になる、こうしたエラーが生じないようインポートの際には工夫が必要だと思われる
          </p>
        </div>{" "}
      </div>
      <div className="management-content">
        <div className="management-field">
          <button
            className="management-button"
            onClick={() => navigate(`/jsontocsv`)}
          >
            バックアップをCSVに{" "}
          </button>
        </div>{" "}
      </div>
      <div className="management-content"></div>
    </div>
  );
}

export default Management;
