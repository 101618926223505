import React, { useEffect, useState } from "react";
import "./TennisOff.scss";
import { useNavigate } from "react-router-dom";

const TennisOff = () => {
  const [offList, setOffList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // 外部スクリプトからデータを取得して処理
    const script = document.createElement("script");
    script.src =
      "https://www.tennisoff.net/livelink/show?leaderid=raising_2004&lmt=5&ord_col=dt&ord_desc=1&o_rec=1&o_wal=1&o_rep=1&o_com=1&o_abo=0&js_br=0";
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => {
      // スクリプトが読み込まれた後に `offList` をセットする
      const offListData = window.offList || [];
      console.log(window.offList);
      setOffList(offListData);
      // リンク先URLを抽出して配列に格納
      var links = offListData.map((item) => {
        // 正規表現で href="..." を抽出
        var match = item.offLinkHtmlStr.match(/href="([^"]+)"/);
        return match ? match[1] : null; // マッチした場合はURLを返す
      });

      // 結果を表示
      console.log(links);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="tennisoff-container">
      <div className="tennisoff-content">
        <div className="tennisoff-field">
          <h1>テニスオフの開催状況</h1>
          <div className="tennsioff-eventlist">
            <button
              onClick={() => {
                navigate(`/eventlist`);
              }}
            >
              eventlist
            </button>
          </div>
          <img
            src={"/images/calendar.png"}
            alt="カレンダー"
            className="tennisoff-calendar-image"
            onClick={() => {
              navigate(`/calendar`);
            }}
          ></img>
        </div>
        <table>
          <tbody>
            {offList.map((event, index) => (
              <tr key={index}>
                <td
                  className="tennisoff-schedule"
                  dangerouslySetInnerHTML={{ __html: event.offDateHtmlStr }}
                />
                <td
                  className="tennisoff-title"
                  dangerouslySetInnerHTML={{ __html: event.offLinkHtmlStr }}
                />
                <td
                  className="tennisoff-state"
                  dangerouslySetInnerHTML={{ __html: event.offStatusHtmlStr }}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TennisOff;
