import { createUserWithEmailAndPassword } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // firebase.jsからインポート
import "./SignUpForm.scss";
import { enableIndexedDbPersistence } from "firebase/firestore";

const SignUpForm = ({ setIsAuth }) => {
  const navigate = useNavigate();

  const [signupSuccess, setSignupSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const emailInputRef = useRef();

  // サインアップ処理
  const handleSignUp = async (e) => {
    e.preventDefault();
    const target = e.target;
    const email = target.email.value;
    const password = target.password.value;

    // パスワードと確認用パスワードが一致しているかチェック
    if (password !== passwordConfirmation) {
      alert("パスワードが一致しません。");
      return;
    }

    try {
      if (
        password.length < 6 || // 最低6文字
        password.length > 15 || // 最大15文字
        !/[a-z]/.test(password) || // 小文字が含まれているか
        !/\d/.test(password) // 数字が含まれているか
      ) {
        throw new Error(
          "パスワードは少なくとも6文字で英語の小文字、数字を含む必要があり、最大15文字です。"
        );
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      setSignupSuccess(true); // 登録成功時にsignupSuccessをtrueに設定
      setTimeout(() => {
        navigate("/emaillogin");
      }, 3000); // 10秒後にログインページへ移動
    } catch (e) {
      if (e.code === "auth/email-already-in-use") {
        alert("このメールアドレスはすでに使用されています。");
      } else if (e.code === "auth/invalid-email") {
        alert("無効なメールアドレスです。");
      } else if (e.code === "auth/weak-password") {
        alert("パスワードが弱すぎます。");
      } else {
        alert("登録中にエラーが発生しました。");
      }
    }
  };

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleHomeRedirect = () => {
    // localStorage.setItem("isAuth", true);
    // setIsAuth(true);
    navigate("/home");
  };

  const adjustInputWidth = () => {
    if (emailInputRef.current) {
      const minWidth = 265;
      const maxWidth = 500;
      emailInputRef.current.style.width = `${minWidth}px`;
      const scrollWidth = emailInputRef.current.scrollWidth;
      if (scrollWidth > minWidth && scrollWidth < maxWidth) {
        emailInputRef.current.style.width = `${scrollWidth}px`;
      } else if (scrollWidth >= maxWidth) {
        emailInputRef.current.style.width = `${maxWidth}px`;
      }
    }
  };

  useEffect(() => {
    adjustInputWidth(); // 初期ロード時に幅を調整
  }, []);

  return (
    <div className="signup-container">
      <div className="signup-content"></div>
      <div className="signup-content">
        {!signupSuccess ? (
          <form className="" onSubmit={handleSignUp}>
            <div className="signup-form">
              <button
                className="signup-close-button"
                onClick={() => {
                  navigate("/login");
                }}
              >
                ｘ
              </button>
              <p>新規登録</p>
            </div>
            <div className="signup-form">
              <p>email</p>
              <input
                className="signup-input-email"
                name="email"
                type="email"
                ref={emailInputRef}
              ></input>
            </div>
            <div className="signup-form">
              <div className="signup-p">
                <p>パスワード</p>
              </div>
              <label className="signup-lubi">
                6〜15文字で英語の小文字、数字を含むもの
              </label>
              <input
                className="signup-input-password"
                name="password"
                type={showPassword ? "text" : "password"}
              ></input>
              <div className="signup-password-show-button-wrapper">
                <button
                  className="signup-password-show-button"
                  type="button"
                  onMouseDown={() => setShowPassword(true)}
                  onMouseUp={() => setShowPassword(false)}
                  onMouseLeave={() => setShowPassword(false)}
                  onTouchStart={() => setShowPassword(true)}
                  onTouchEnd={() => setShowPassword(false)}
                  onClick={handleTogglePasswordVisibility}
                >
                  Show
                </button>
              </div>
            </div>
            <div className="signup-form">
              <div className="signup-p2">
                <p>パスワード再確認</p>
              </div>
              <input
                className="signup-input-password"
                type="password"
                value={passwordConfirmation}
                onChange={handlePasswordConfirmationChange}
              ></input>
            </div>
            <div className="signup-form">
              <button className="signup-submit-button" type="submit">
                新規登録
              </button>
            </div>
          </form>
        ) : (
          <div className="signup-success-message" onClick={handleHomeRedirect}>
            登録が成功しました！
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpForm;
