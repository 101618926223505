import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MemberContext } from "../Contexts/MemberContext";
import { auth, db } from "../firebase";
import "./MemberInfo.scss";
import { sendRegistrationEmail } from "./EmailComponents/RegistEmailSender";

const MemberInfo = () => {
  const navigate = useNavigate();
  const { setMembersList, membersList, currentUserInfo } =
    useContext(MemberContext);
  const location = useLocation();
  const { emptyCollection, authorId } = location.state || {};
  const [toEmail, setToEmail] = useState("");
  const { id } = useParams();
  const [member, setMember] = useState({
    realname: "",
    accountname: "",
    email: "",
    tel_num: "",
    birthday: "",
    rank: "",
    profile: "",
    photo: "",
    administrator: false,
    admin: false,
    author: {
      username: "",
      id: "",
    },
  });
  const [canEdit, setCanEdit] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  console.log("ぱらむす" + id);
  // `emptyCollection`がtrueの場合の初期設定
  useEffect(() => {
    if (emptyCollection) {
      alert(
        "あなたは最初に登録された方として管理者としての管理権限が付与されました。"
      );
      setMember((prevMember) => ({
        ...prevMember,
        administrator: true,
        admin: true,
      }));
    }
  }, [emptyCollection]);

  // メンバー一覧からauthorIdが送られてきたとき、そのデータをmemberデータにセット
  useEffect(() => {
    if (authorId) {
      const memberDoc = membersList.find(
        (member) => member.author.id === authorId
      );
      if (memberDoc) {
        setMember({ ...memberDoc });
        setIsEditing(true);
      } else {
        console.log("イベントが見つかりませんでした");
      }
    } else {
      // console.log("新規");
      setCanEdit(!canEdit);
    }
  }, [authorId, membersList]);

  const canShowEditOption =
    currentUserInfo?.administrator === true ||
    (currentUserInfo?.author?.id === authorId && authorId !== undefined);
  const canShowEditOptionAdmin = currentUserInfo?.administrator === true;

  // 入力変更ハンドラー
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMember((prevMember) => ({
      ...prevMember,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // 会員情報送信処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (isSubmitting) return; // 二重送信を防ぐ
    setIsSubmitting(true);

    if (!user) {
      setError("ログインしてから入力してください。");
      return;
    }
    let updatedMember = {};
    try {
      if (isEditing) {
        updatedMember = {
          ...member,
        };

        if (!updatedMember.id) {
          throw new Error("Member ID is missing");
        }
        await updateDoc(doc(db, "members", updatedMember.id), updatedMember);
        navigate("/memberlist");
      } else if (id === "create") {
        const username = user.displayName;
        const nonMember = ("nonMember" + JSON.stringify(new Date())).substring(
          0,
          28
        );
        updatedMember = {
          ...member,
          author: {
            id: nonMember,
            username: username,
          },
        };
        await addDoc(collection(db, "members"), updatedMember);
      } else {
        const userId = user.uid;
        const username = user.displayName;
        updatedMember = {
          ...member,
          author: {
            id: userId,
            username: username,
          },
        };
        await addDoc(collection(db, "members"), updatedMember);
      }
      // メンバー追加後にリストを再取得して更新
      const updatedMembers = await getDocs(collection(db, "members"));
      const members = updatedMembers.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMembersList(members);

      // メール送信部分
      const adminmembers = members.filter(
        (member) => member.administrator === true
      );
      const adminEmailString = adminmembers
        .map((member) => member.email)
        .join(", ");

      setToEmail(adminEmailString);

      const emailPayloads = [];

      if (updatedMember.administrator) {
        const subject = "管理者登録";
        const text = `管理者として登録されました。管理ページで各種の設定を行ってください。\n\n名前: ${updatedMember.realname}\n\nアカウント名: ${updatedMember.accountname}\n\n新たな登録者の参加許可のメールがあった場合、承認をしてください。\n\nhttp://localhost:3000/memberlist`;
        emailPayloads.push({ to: adminEmailString, subject, text });
      } else {
        const adminEmailPayload = {
          to: adminEmailString,
          subject: "新たなメンバーの登録",
          text: `新たな方からメンバー登録がされました。\n\nお名前: ${updatedMember.realname}\nアカウント名: ${updatedMember.accountname}\nメールアドレス: ${updatedMember.email}\n\n参加申し込みができるようメンバー情報で承認処理を行ってください。\n\nhttp://localhost:3000/memberlist`,
        };
        const memberEmailPayload = {
          to: updatedMember.email,
          subject: "新規登録のお知らせ",
          text: `新規登録が完了しました。\n\nお名前: ${updatedMember.realname}\nアカウント名: ${updatedMember.accountname}\nメールアドレス: ${updatedMember.email}\n\n管理者が承認手続きを行うまでお待ちください。`,
        };
        emailPayloads.push(adminEmailPayload, memberEmailPayload);
      }

      if (isEditing || id === "create") {
        console.log("更新若しくは会員外の登録ですのでメールをおくりません。");
      } else {
        for (const emailPayload of emailPayloads) {
          await sendRegistrationEmail(emailPayload);
        }
      }
      setError("");
      navigate("/memberlist");
    } catch (error) {
      console.error("Error adding document: ", error);
      setError(`データの登録中にエラーが発生しました。詳細: ${error.message}`);
    } finally {
      setIsSubmitting(false); // 処理が終わったらボタンを有効化
    }
  };

  return (
    <div className="memberinfo-container">
      <div className="memberinfo-content">
        <div className="memberinfo-title">
          <div className="memberlist-content">
            <button
              className="memberinfo-close-button"
              onClick={() => {
                navigate("/memberlist");
              }}
            >
              ｘ
            </button>
          </div>{" "}
          {id !== "create" ? (
            <h1>会員情報登録</h1>
          ) : (
            <h1>会員外の方の名前登録</h1>
          )}
          {canShowEditOption && (
            <div className="memberinfo-auth">
              <label>編集可にする</label>
              <input
                type="checkbox"
                onChange={() => setCanEdit(!canEdit)}
                checked={canEdit}
              />
            </div>
          )}
          {error && <p className="memberinfo-error">{error}</p>}
        </div>
        <div
          className="memberinfo-message"
          style={emptyCollection ? {} : { display: "none" }}
        >
          <p className="memberinfo-message-alert">
            管理者権限が設定されました.
            <br />
            <span className="small-text">
              今後は新会員の参加申し込みの許可や日程追加、データーバックアップ等の管理者としての作業を行ってください。
            </span>
          </p>
        </div>
        <form className="memberinfo-content-form" onSubmit={handleSubmit}>
          {id !== "create" ? (
            <div className="memberinfo-formfield">
              <label>お名前:</label>
              <input
                className="memberinfo-formfield-input"
                type="text"
                name="username"
                value={member.username}
                onChange={handleChange}
                disabled={!canEdit}
                required
              />
            </div>
          ) : (
            ""
          )}

          <div className="memberinfo-formfield">
            <label>アカウント名:</label>
            <input
              className="memberinfo-formfield-input"
              type="text"
              name="accountname"
              value={member.accountname}
              onChange={handleChange}
              disabled={!canEdit}
              required
            />
          </div>
          {id !== "create" ? (
            <div className="memberinfo-formfield">
              <label>連絡用メールアドレス:</label>
              <input
                className="memberinfo-formfield-input"
                type="email"
                name="email"
                value={member.email}
                onChange={handleChange}
                disabled={!canEdit}
              />
            </div>
          ) : (
            ""
          )}
          {id !== "create" ? (
            <div className="memberinfo-formfield">
              <label>連絡用携帯電話番号:</label>
              <input
                className="memberinfo-formfield-input"
                type="tel"
                name="tel_num"
                value={member.tel_num}
                onChange={handleChange}
                disabled={!canEdit}
              />
            </div>
          ) : (
            ""
          )}
          {/* {id !== "create" ? (
            <div className="memberinfo-formfield">
              <label>生年月日:</label>
              <input
                className="memberinfo-formfield-input"
                type="date"
                name="birthday"
                value={member.birthday}
                onChange={handleChange}
                disabled={!canEdit}
              />
            </div>
          ) : (
            ""
          )} */}
          {/* {id !== "create" ? (
            <div className="memberinfo-formfield">
              <label>ランク:</label>
              <input
                className="memberinfo-formfield-input"
                type="text"
                name="rank"
                value={member.rank}
                onChange={handleChange}
                disabled={!canEdit}
              />
            </div>
          ) : (
            ""
          )} */}
          {id !== "create" ? (
            <div className="memberinfo-formfield">
              <div className="memberinfo-formfield-inner">
                <label>プロフィール</label>
                <textarea
                  className="memberinfo-formfield-textarea"
                  name="profile"
                  value={member.profile}
                  onChange={handleChange}
                  disabled={!canEdit}
                ></textarea>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="memberinfo-formfield">
            {canShowEditOptionAdmin && id !== "create" && (
              <div className="memberinfo-formfield">
                {" "}
                <div className="memberinfo-formfield-inner2">
                  <label>参加申し込みを許可:</label>
                  <input
                    className="memberinfo-checkbox"
                    type="checkbox"
                    name="admin"
                    checked={member.admin}
                    onChange={handleChange}
                    disabled={emptyCollection}
                  />
                </div>
              </div>
            )}
            {canShowEditOptionAdmin && id !== "create" && (
              <div className="memberinfo-formfield">
                <div className="memberinfo-formfield-inner2">
                  <label>管理者としての権限を設定:</label>
                  <input
                    className="memberinfo-checkbox"
                    type="checkbox"
                    name="administrator"
                    checked={member.administrator}
                    onChange={handleChange}
                    disabled={emptyCollection}
                  />
                </div>
              </div>
            )}
          </div>
          {canEdit && id !== "create" && (
            <div className="memberinfo-formfield">
              <button className="memberinfo-submit-button" type="submit">
                編集内容登録
              </button>
            </div>
          )}
          {id === "create" && (
            <div className="memberinfo-formfield">
              <button className="memberinfo-submit-button" type="submit">
                新規登録
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default MemberInfo;
