export const eventPaticiantCheck = (
  eventId,
  currentUserInfo,
  eventMembersList
) => {
  const applyMember = eventMembersList.filter(
    (event) => event.eventId === eventId
  );
  let checkapply = false;
  if (currentUserInfo && currentUserInfo.author && currentUserInfo.author.id) {
    checkapply = !!applyMember.find(
      (member) => member.memberId === currentUserInfo.author.id
    );
  } else {
    console.log("currentUserInfo が null または不完全です: ", currentUserInfo);
  }

  return checkapply;
};
