import { EventContext } from "Contexts/EventContext";
import { EventMembersContext } from "Contexts/EventMembersContext";
import { MemberContext, currentUserInfo } from "Contexts/MemberContext";
import { addWeeks, format, isAfter, isBefore } from "date-fns";
import ja from "date-fns/locale/ja";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { data, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import "./EventList.scss";
import { ParticipantList } from "./ParticipantList";
import { EventDelete } from "../utils/EventDelete";
import { EmailSenderBase } from "../utils/EmailSenderBase";
import { endTimeCalclater } from "../utils/endTimeCalclater";

const EventList = () => {
  const { eventList } = useContext(EventContext);
  const { eventMembersList } = useContext(EventMembersContext);
  const { membersList, currentUserInfo } = useContext(MemberContext);
  const [events, setEvents] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [eventMembers, setEventMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [userEventParticipation, setUserEventParticipation] = useState({});
  const [participantCounts, setParticipantCounts] = useState({});
  const navigate = useNavigate();
  const [showSaturdayEvents, setShowSaturdayEvents] = useState(false);
  const [showSundayEvents, setShowSundayEvents] = useState(false);
  const [showWeekdayEvents, setShowWeekdayEvents] = useState(false);
  const [enableJoin, setEnableJoin] = useState(false);
  const [latestEvent, setLatestEvent] = useState(null);
  const [hasAlertBeenShown, setHasAlertBeenShown] = useState(false);
  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const [toEmail, setToEmail] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [newEndTime, setNewEndTime] = useState();
  const [eventsWithEndTime, setEventsWithEndTime] = useState([]);
  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
    // if (!user) {
    //   alert("ログインしてください");
    //   navigate("/login");
    // }
    setEvents(eventList || []);
    setEventMembers(eventMembersList || []);
    setMembers(membersList || []);
    if (user) {
      const userId = user.uid;

      // ユーザーが参加しているイベントのリストを取得
      const userEventMembers = eventMembersList.filter(
        (member) => member.memberId === userId
      );

      const userEventIds = userEventMembers.map((member) => member.eventId);
      const userEvents = events.filter((event) =>
        userEventIds.includes(event.id)
      );

      setUserEventParticipation(
        userEvents.reduce((acc, event) => {
          acc[event.id] = true;
          return acc;
        }, {})
      );
    } else {
      // ユーザーがログインしていない場合でも処理を行う（必要に応じて）
      console.log("認証されていないユーザーでもイベント情報は表示されます");
    }
  }, [eventList, eventMembersList, membersList]);

  useEffect(() => {
    const adminmembers = membersList.filter(
      (member) => member.administrator === true
    );
    const adminEmailString = adminmembers
      .map((member) => member.email)
      .join(", ");

    setToEmail(adminEmailString); // 初期レンダリング時や membersList の変更時のみ実行
  }, [membersList]);

  useEffect(() => {
    const fetchData = async () => {
      // イベント一覧を取得
      // 現在の時間の翌日を取得
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate()); // 翌日を取得
      tomorrow.setHours(0, 0, 0, 0); // 翌日の00:00に設定

      // イベントを現在の日付以降でフィルタリング
      const filteredEventList = eventList.filter(
        (event) => event.starttime >= tomorrow
      );

      // 日付の早い順にソート
      filteredEventList.sort((a, b) => a.starttime - b.starttime);

      const updatedEvents = filteredEventList.map((event) => {
        // 終了時間の計算
        const newEndTime = endTimeCalclater(event.id, eventList);
        return {
          ...event,
          endTime: newEndTime, // 計算した終了時間を追加
        };
      });

      setEvents(updatedEvents);

      // ユーザー情報を取得
      const user = auth.currentUser;
      setCurrentUser(user);
      if (user) {
        const userId = user.uid;

        // 現在ログイン中のユーザーの参加イベント情報を取得
        const userEventMembers = eventMembersList.filter(
          (member) => member.memberId === userId
        );

        // ユーザーが参加しているイベントのリストを取得
        const userEventIds = userEventMembers.map((member) => member.eventId);
        const userEvents = filteredEventList.filter((event) =>
          userEventIds.includes(event.id)
        );

        // 最も未来のイベントを取得
        const mostFutureEvent = userEvents.reduce((latest, event) => {
          return !latest || isAfter(event.starttime, latest.starttime)
            ? event
            : latest;
        }, null);

        setLatestEvent(mostFutureEvent);

        //現在ログイン中のユーザーの参加するイベント情報を抽出
        const userParticipation = userEventMembers.reduce((acc, member) => {
          acc[member.eventId] = true;
          return acc;
        }, {});
        setUserEventParticipation(userParticipation);

        // ユーザーがメンバーかどうかをチェック
        const filteredMembers = membersList.filter(
          (member) => member.author.id === userId
        );
        if (filteredMembers.length === 0) {
          alert(
            "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
          );
          navigate("/memberinfo");
        }
      }
    };

    fetchData();
  }, [navigate, eventList, eventMembersList, membersList]);

  useEffect(() => {
    const fetchParticipantCounts = () => {
      const counts = events.reduce((acc, event) => {
        const participants = eventMembers.filter(
          (member) => member.eventId === event.id
        );
        acc[event.id] = participants.length;
        return acc;
      }, {});
      setParticipantCounts(counts);
    };

    fetchParticipantCounts();
  }, [events, eventMembers]);

  const handleJoinEvent = async (eventId) => {
    if (!currentUserInfo) {
      alert("、会員登録及び管理者認定をお願いいたします");
      navigate("/login");
      return;
    }
    if (currentUserInfo.admin === false) {
      alert("管理者による認証がまだなされていません。");
      return;
    }

    // if (!currentUser) {
    //   console.log("User is not logged in");
    //   alert("ログインしてください");
    //   navigate("/login");
    //   return;
    // }

    if (!enableJoin) {
      alert(
        "参加ボタン有効化か確認アラート不要をチェックしてください（後者をチェックした場合、確認アラートの表示が省略できます）"
      );
      return;
    }

    // イベント情報を取得
    const event = events.find((e) => e.id === eventId);
    if (!event) {
      alert("イベントが見つかりません");
      return;
    }

    // イベントへの参加確認
    if (!skipConfirmation) {
      const confirmMessage = `${format(event.starttime, "M月d日 (E) H:mm", {
        locale: ja,
      })} のイベントに本当に参加しますか？`;
      if (!window.confirm(confirmMessage)) {
        return; // ユーザーがキャンセルした場合、何もしない
      }
    }

    /**
     * 現在の参加者数を取得
     */
    const currentParticipantsCount = participantCounts[eventId] || 0;

    /**
     * 定員を確認
     */
    if (currentParticipantsCount >= event.capacity) {
      alert("定員締め切りのため、参加できません。");
      return;
    }

    /**
     * ユーザーのアカウント名を取得
     */
    const user = members.find((member) => member.author.id === currentUser.uid);
    if (!user) {
      alert(
        "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
      );
      navigate("/member");
      return;
    }

    const accountname = user.accountname;

    // すでにイベントメンバーとして存在するかをチェック
    const existingMember = eventMembers.find(
      (member) =>
        member.eventId === eventId && member.memberId === currentUser.uid
    );
    if (existingMember) {
      alert("すでに参加申込しています。");
      return;
    }

    //申し込みした日が２週間より前か否かを確認
    // 本日の日付を取得（開始時間を 00:00:00 にリセット）
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (!event) {
      return <p>Loading...</p>;
    }

    const startTime = new Date(event.starttime);
    startTime.setHours(0, 0, 0, 0); // 時刻をリセット
    const daysDifference = (startTime - today) / (1000 * 60 * 60 * 24);
    const twoWeeksFromNow = daysDifference - 14 > 1;

    //テニスオフ募集開始以降の場合はメールを送信
    if (!twoWeeksFromNow) {
      const subject = "二週間前以降の参加表明";
      // const toEmail2 = toEmail.map((item) => item.email);
      // console.log(toEmail2);
      console.log(toEmail);
      const emailContent = [
        {
          email: [toEmail],
          text: `二週間前以降の${format(event.starttime, "M /d（E）HH:mm ", {
            locale: ja,
          })}の${event.title}の参加表明が${
            currentUserInfo.accountname
          }さんからありましたので、テニスオフでの調整などを行ってください。
          \n\nhttps://raising2024-7e234.web.app/eventdetail/${eventId}`,
        },
      ];
      console.log(emailContent);
      emailContent.forEach(({ email, text }) => {
        EmailSenderBase([email], subject, text, setResponseMessage); // それぞれに個別に送信
      });
      // alert("参加申し込みの連絡メールが送信されました。");
    }

    // イベントメンバーに新しいドキュメントを追加し、そのドキュメントIDを取得
    const docRef = await addDoc(collection(db, "event_members"), {
      eventId: eventId,
      memberId: currentUser.uid,
    });

    // ドキュメントIDを取得
    const docId = docRef.id;

    // ローカルのステートを更新
    setEventMembers((prevEventMembers) => [
      ...prevEventMembers,
      { eventId, memberId: currentUser.uid, id: docId },
    ]);

    // ユーザーの参加状況を更新
    setUserEventParticipation((prevParticipation) => ({
      ...prevParticipation,
      [eventId]: true,
    }));

    // Firestoreから最新のデータをフェッチ
    const eventMembersCollection = collection(db, "event_members");
    const eventMembersSnapshot = await getDocs(eventMembersCollection);
    const eventMembersList = eventMembersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEventMembers(eventMembersList);
  };

  /**
   * イベントを削除
   * @param {*} id
   * @returns
   */
  const handleDelete = async (eventId) => {
    const deleteEvent = events.filter((event) => event.id === eventId);
    const dateObject = new Date(deleteEvent[0].starttime);
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const confirmDelete = window.confirm(
      `本当に${month}月${day}日の${deleteEvent[0].title}を削除しますか？`
    );

    if (!confirmDelete) return;
    try {
      console.log(eventId);
      // バッチ処理を開始
      const batch = writeBatch(db);
      console.log(eventId);
      // events コレクションから該当するドキュメントを削除
      const eventRef = doc(db, "events", eventId);
      batch.delete(eventRef);

      //event_members コレクションから該当するドキュメントを検索して削除
      const eventMembersQuery = query(
        collection(db, "event_members"),
        where("eventId", "==", eventId)
      );
      const eventMembersSnapshot = await getDocs(eventMembersQuery);

      eventMembersSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      //バッチをコミット
      await batch.commit();
      console.log("イベントと関連する参加者情報が削除されました");
    } catch (error) {
      console.error("削除中にエラーが発生しました:", error);
    }
  };

  /**
   * 管理者権限の有無をisAdminに格納
   */
  const isAdmin = currentUserInfo?.administrator === true;

  //テニスオフで募集している情報を表示する。

  /**
   * 一覧表示におけるチェックボックスの管理
   */
  const handleWeekdayCheckboxChange = () => {
    setShowWeekdayEvents(!showWeekdayEvents);
  };

  const handleSaturdayCheckboxChange = () => {
    setShowSaturdayEvents(!showSaturdayEvents);
  };

  const handleSundayCheckboxChange = () => {
    setShowSundayEvents(!showSundayEvents);
  };

  /**
   * 一覧情報をチェックボックスの状況に応じて選別
   */
  const filteredEvents = eventsWithEndTime.filter((event) => {
    const dayOfWeek = event.starttime.getDay();
    if (!showSaturdayEvents && !showSundayEvents && !showWeekdayEvents) {
      return true;
    }

    // 土曜日だけ表示する
    if (showSaturdayEvents && !showSundayEvents && !showWeekdayEvents) {
      return dayOfWeek === 6;
    }

    if (!showSaturdayEvents && showSundayEvents && !showWeekdayEvents) {
      return dayOfWeek === 0;
    }

    if (!showSaturdayEvents && !showSundayEvents && showWeekdayEvents) {
      return dayOfWeek >= 1 && dayOfWeek <= 5;
    }

    if (showSaturdayEvents && showSundayEvents && !showWeekdayEvents) {
      return dayOfWeek === 0 || dayOfWeek === 6;
    }

    if (showSaturdayEvents && !showSundayEvents && showWeekdayEvents) {
      return dayOfWeek === 6 || (dayOfWeek >= 1 && dayOfWeek <= 5);
    }

    if (!showSaturdayEvents && showSundayEvents && showWeekdayEvents) {
      return dayOfWeek === 0 || (dayOfWeek >= 1 && dayOfWeek <= 5);
    }

    if (showSaturdayEvents && showSundayEvents && showWeekdayEvents) {
      return true;
    }

    return false;
  });
  useEffect(() => {
    const checkAlert = () => {
      if (latestEvent) {
        const now = new Date();

        const eventStartTime = latestEvent.starttime;

        const threeWeeksFromNow = addWeeks(now, 3);

        if (isBefore(eventStartTime, threeWeeksFromNow) && !hasAlertBeenShown) {
          alert(
            "テニスオフでの参加募集は２週間前開始ですので、参加申し込みはできる限りそれまでにお願いいたします。"
          );
          setHasAlertBeenShown(true);
        }
      }
    };

    checkAlert();
  }, [latestEvent, hasAlertBeenShown]);

  let uid;
  if (currentUser) {
    uid = currentUser.uid;
    console.log(`UID: ${uid}`);
  } else {
    console.log("currentUser が存在しません");
  }

  return (
    <div className="eventlist-container">
      <div className={`eventlist-content ${isAdmin ? "" : "member"}`}>
        <div className="eventList-lable">
          <h1>イベント一覧</h1>
          <div className="calendar_image">
            <img
              src={"/images/calendar.png"}
              alt="カレンダー"
              className="event-image"
              onClick={() => {
                navigate(`/calendar`);
              }}
            ></img>
          </div>
        </div>
        <div className="eventlist-title">
          <div className="eventlist-filter">
            <div className="eventlist-filter-in">
              <h3>表示する曜日を選択</h3>
              <label>
                <input
                  type="checkbox"
                  checked={showSaturdayEvents}
                  onChange={handleSaturdayCheckboxChange}
                />
                土
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showSundayEvents}
                  onChange={handleSundayCheckboxChange}
                />
                日
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showWeekdayEvents}
                  onChange={handleWeekdayCheckboxChange}
                />
                平日
              </label>
            </div>{" "}
            <div className="eventlist-filter-in2">
              <label>
                <input
                  type="checkbox"
                  checked={enableJoin}
                  onChange={() => setEnableJoin(!enableJoin)}
                />
                参加ボタン有効化
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={skipConfirmation}
                  onChange={() => {
                    setSkipConfirmation(!skipConfirmation);
                    setEnableJoin(!enableJoin);
                  }}
                />
                確認アラート不要
              </label>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th className="event_title" style={{ width: "200px" }}>
                開催日時
              </th>
              <th className="event_title hide-on-narrow">開催時間</th>
              <th className="event_title"> タイトル</th>
              <th className="event_title hide-on-narrow">開催場所</th>
              <th className="event_title hide-on-narrow">定員</th>
              <th className="event_title hide-on-narrow">参加者</th>
              <th className="event_title_apply ">参加</th>
              {isAdmin && (
                <>
                  <th className="event_title hide-on-narrow">削除</th>
                  <th className="event_title hide-on-narrow">編集</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => {
              // 曜日を取得
              const dayOfWeek = format(event.starttime, "E", { locale: ja }); // 曜日を短縮形で取得
              const dayStyle = {
                color:
                  dayOfWeek === "土"
                    ? "blue"
                    : dayOfWeek === "日"
                    ? "red"
                    : "black", // 土曜: 青, 日曜: 赤, 他は黒
              };

              return (
                <tr key={index}>
                  <td
                    className="event_button_title"
                    onClick={() => navigate(`/eventdetail/${event.id}`)}
                  >
                    {format(event.starttime, "M /d(", { locale: ja })}
                    <span style={dayStyle}>
                      {format(event.starttime, "E", { locale: ja })}
                    </span>
                    )
                    <span className="hide-on-narrow">
                      <br />
                      {format(event.starttime, "a", { locale: ja }) === "am"
                        ? "　午後"
                        : "　午前"}
                      {format(event.starttime, "h時", { locale: ja })}
                      {event.starttime_minte > 0
                        ? format(event.starttime, "mm分", { locale: ja })
                        : ""}
                      ～
                    </span>
                    <span className="show-on-narrow">
                      {format(event.starttime, "H")}:
                      {format(event.starttime, "mm")}~
                      {event.endTime > 0
                        ? format(new Date(event.endTime), "H")
                        : ""}
                      :
                      {event.endTime > 0
                        ? format(new Date(event.endTime), "mm")
                        : ""}
                    </span>
                  </td>
                  <td className="hide-on-narrow">
                    {event.duration_hour > 0
                      ? `${parseInt(event.duration_hour)} 時間 `
                      : ""}

                    {event.duration_minute > 0
                      ? `${event.duration_minute} 分`
                      : ""}
                    {event.duration_minute > 0
                      ? `${event.duration_minute} 分`
                      : ""}
                  </td>
                  <td
                    className="event_button_title"
                    onClick={() => navigate(`/eventdetail/${event.id}`)}
                  >
                    {event.title}
                  </td>
                  <td className="hide-on-narrow">{event.site_region}</td>
                  <td className="hide-on-narrow">{event.capacity}</td>
                  <td className="hide-on-narrow">
                    <label htmlFor={`participants-${event.id}`}></label>
                    <div className="participantList">
                      <ParticipantList
                        id={`participants-${event.id}`}
                        eventId={event.id}
                        eventMembers={eventMembers}
                        members={members}
                        navigate={navigate}
                        administrator={isAdmin}
                        currentUser={currentUser}
                      />
                    </div>
                  </td>
                  <td>
                    {userEventParticipation[event.id] ? (
                      <button
                        className="join-button"
                        onClick={() => handleJoinEvent(event.id)}
                      >
                        参加予定
                      </button>
                    ) : participantCounts[event.id] >= event.capacity ? (
                      <button
                        className="limit-button"
                        disabled
                        style={{
                          cursor: "not-allowed",
                          backgroundColor: "#a99fe9",
                        }}
                      >
                        定員締切
                      </button>
                    ) : (
                      <button
                        className="aplay-button"
                        onClick={() => handleJoinEvent(event.id)}
                        style={{
                          backgroundColor: "#ffcc6f",
                        }}
                      >
                        募集中
                      </button>
                    )}
                  </td>
                  {isAdmin && (
                    <>
                      <td className="hide-on-narrow">
                        <button
                          className="eventlist-delete-button"
                          onClick={() => handleDelete(event.id)}
                        >
                          削除
                        </button>
                      </td>
                      <td className="hide-on-narrow">
                        <button
                          className="eventlist-edit-button"
                          onClick={() =>
                            navigate(`/eventinfo/${event.id}/edit`)
                          }
                        >
                          編集
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventList;
