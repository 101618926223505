import { useRef, useState } from "react";
import "./EmailSender.scss";

function EmailSender() {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  // すべてのinput, textareaを格納するref
  const inputRefs = [useRef(null), useRef(null), useRef(null)];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        // "http://127.0.0.1:5001/raising2024-7e234/us-central1/sendEmail",
        "https://us-central1-raising2024-7e234.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ to, subject, text }),
        }
      );

      if (!response.ok) {
        throw new Error("メール送信に失敗しました");
      }

      const data = await response.json();
      setResponseMessage(data.message);
    } catch (error) {
      console.error("エラー:", error);
      setResponseMessage("メール送信中にエラーが発生しました。");
    }
  };

  const handleMouseEnter = (index) => {
    if (inputRefs[index].current) {
      inputRefs[index].current.focus(); // 対象のrefにフォーカスを当てる
    }
  };

  // Enterキーで次の入力欄にフォーカスを移動
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault(); // フォーム送信を防ぐ
      if (inputRefs[index + 1]?.current) {
        inputRefs[index + 1].current.focus(); // 次の入力欄にフォーカスを移動
      }
    }
  };

  return (
    <div className="email-sender-container">
      <div className="email-sender-content">
        <h1 className="email-sender-title">メール送信フォーム</h1>
        <form className="email-sender-form" onSubmit={handleSubmit}>
          <div className="email-sender-field">
            <label
              className="email-sender-label"
              onMouseEnter={() => handleMouseEnter(0)}
            >
              宛先メールアドレス（カンマ区切り）:
            </label>
            <input
              ref={inputRefs[0]}
              className="email-sender-input"
              id="email-adress"
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              placeholder="例: email1@example.com, email2@example.com"
              required
              onMouseEnter={() => handleMouseEnter(0)}
              onKeyDown={(e) => handleKeyDown(e, 0)} // Enterキーが押された時の処理
            />
          </div>
          <div className="email-sender-field">
            <label
              className="email-sender-label"
              onMouseEnter={() => handleMouseEnter(1)}
            >
              件名:
            </label>
            <input
              ref={inputRefs[1]}
              className="email-sender-input"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              onMouseEnter={() => handleMouseEnter(1)}
              onKeyDown={(e) => handleKeyDown(e, 1)} // Enterキーが押された時の処理
            />
          </div>
          <div className="email-sender-field">
            <label
              className="email-sender-label"
              onMouseEnter={() => handleMouseEnter(2)}
            >
              本文:
            </label>
            <textarea
              ref={inputRefs[2]}
              className="email-sender-textarea"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
              onMouseEnter={() => handleMouseEnter(2)}
            />
          </div>
          <button className="email-sender-button" type="submit">
            送信
          </button>
        </form>
        {responseMessage && (
          <h2 className="email-sender-response">{responseMessage}</h2>
        )}
      </div>
    </div>
  );
}

export default EmailSender;
