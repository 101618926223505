import React, { useState } from "react";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./ResetEmailPassword.scss";

const ResetEmailPassword = () => {
  const [resetEmail, setResetEmail] = useState("");
  const navigate = useNavigate();

  const cancel = () => {
    navigate("/login"); // ログイン画面に戻る
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    const firebaseAuth = getAuth();

    try {
      await sendPasswordResetEmail(firebaseAuth, resetEmail);
      alert("パスワードの再設定のメールを送付しました");
    } catch (error) {
      console.error(error);
      alert("パスワードの再設定のメールの送信に失敗しました");
    }
  };

  return (
    <>
      <div className="email-reset-container">
        <div className="email-reset-content">
          <div className="email-reset-form">
            <div>
              <p>再設定を行うメールアドレス</p>
            </div>
            <div className="email-reset-close-button" onClick={cancel}>
              ｘ
            </div>
            <form onSubmit={handlePasswordReset}>
              <input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                className="email-reset-input-email"
                required
              />
              <button type="submit" className="email-reset-submit-button">
                再設定のメールを送付
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetEmailPassword;
