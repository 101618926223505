import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { useState } from "react";
import "./BackUpImport.scss";
const BackUpImport = () => {
  // const [jsonData, setJsonData] = useState(null);
  const [eventsData, setEventsData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [eventMembersData, setEventMembersData] = useState(null);
  const [membersData, setMembersData] = useState(null);
  // const [jsonData, setJsonData] = useState(null);
  // const [jsonData, setJsonData] = useState(null);

  // CSVデータをJSON形式に変換する関数
  const csvToJson = (csv) => {
    const lines = csv.split("\n");
    const headers = lines[0].split(","); // CSVのヘッダー行
    const result = [];

    // 各行をオブジェクトとして変換
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(",");

      // ヘッダーに対応するキーを設定
      for (let j = 0; j < headers.length; j++) {
        const header = headers[j].trim();
        const value = currentLine[j]?.trim(); // 余分な空白を削除

        if (header === "author_id" || header === "author_username") {
          // author_id と author_username を author にネスト
          const authorKey = header.split("_")[1]; // 'id' または 'username' を取得
          obj.author = obj.author || {}; // author オブジェクトがない場合は作成
          obj.author[authorKey] = value; // id または username をセット
        } else {
          obj[header] = value;
        }
      }
      result.push(obj);
    }
    return result;
  };

  // ファイルを読み込む
  const handleFileRead = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const beforedata = e.target.result;
          const data = csvToJson(beforedata);
          if (fileName.toLowerCase().includes("eventMembers")) {
            setEventMembersData(data);
          } else if (fileName.toLowerCase().includes("events")) {
            setEventsData(data);
          } else if (fileName.toLowerCase().includes("members")) {
            setEventMembersData(data);
            setMembersData(data);
          }

          console.log(data);
        } catch (error) {
          alert("JSONファイルの形式が正しくありません");
        }
      };
      reader.readAsText(file);
    }
  };

  console.log(fileName);

  const handleEventFileUpload = async () => {
    console.log(eventsData);
    const db = getFirestore(); // Firestoreの初期化

    // JSONデータを解析
    // イベントデータをフォーマット
    if (fileName.toLowerCase().includes("eventMembers")) {
      try {
        const eventMembersCollectionRef = collection(db, "event_members"); // "event_members"コレクション
        for (const member of eventMembersData) {
          await addDoc(eventMembersCollectionRef, member);
        }
        console.log("Event members have been successfully added!");
      } catch (error) {
        console.error("Error adding event members: ", error);
      }
      console.log("警報");
    } else if (fileName.toLowerCase().includes("events")) {
      const formattedEvents = eventsData.map((event) => {
        const formattedStarttime = `${event.starttime_date}T${event.starttime_hour}:${event.starttime_minute}:00+09:00`;
        const formattedDuration = `${event.duration_hour.padStart(
          2,
          "0"
        )}:${event.duration_minute.padStart(2, "0")}`;
        return {
          ...event,
          starttime: formattedStarttime,
          duration: formattedDuration,
        };
      });

      try {
        const eventsCollectionRef = collection(db, "events"); // Firestoreの"events"コレクション
        for (const event of formattedEvents) {
          // 元のIDを使用してドキュメントを追加
          const eventRef = doc(eventsCollectionRef, event.id); // event.idを使用
          await setDoc(eventRef, event); // setDocを使って元のIDで保存
        }
        console.log("Events have been successfully added!");
      } catch (error) {
        console.error("Error adding events: ", error);
      }
    } else if (fileName.toLowerCase().includes("members")) {
    }
  };

  const handleEventMemberFileUpload = async () => {
    const db = getFirestore(); // Firestoreの初期化

    try {
      const eventMembersCollectionRef = collection(db, "event_members"); // Firestoreの"events"コレクション
      for (const event_member of eventMembersData) {
        // 元のIDを使用してドキュメントを追加
        const eventRef = doc(eventMembersCollectionRef, event_member.id); // event.idを使用
        await setDoc(eventRef, event_member); // setDocを使って元のIDで保存
      }
      console.log("Events have been successfully added!");
    } catch (error) {
      console.error("Error adding events: ", error);
    }
  };

  const handleMemberFileUpload = async () => {
    const db = getFirestore();
    console.log(membersData);
    try {
      const membersCollectionRef = collection(db, "members"); // "members"コレクション
      for (const member of membersData) {
        await addDoc(membersCollectionRef, member);
      }
      console.log("Members have been successfully added!");
    } catch (error) {
      console.error("Error adding members: ", error);
    }

    try {
      const membersCollectionRef = collection(db, "members"); // Firestoreの"events"コレクション
      for (const member of eventMembersData) {
        // 元のIDを使用してドキュメントを追加
        const eventRef = doc(membersCollectionRef, member.id); // event.idを使用
        await setDoc(eventRef, member); // setDocを使って元のIDで保存
      }
      console.log("Events have been successfully added!");
    } catch (error) {
      console.error("Error adding events: ", error);
    }
  };

  return (
    <div className="backupimport-container">
      <div className="backupimport-content">
        <h1>Firestore Backup Import</h1>
        <div className="buckupiport-field">
          <h1>保存したイベントデータ(CSVファイル)の取り込み</h1>
          <input type="file" accept="" onChange={handleFileRead} />
          <button onClick={() => handleEventFileUpload()}>
            イベントデータのアップロード
          </button>
        </div>
        <div className="buckupiport-field">
          {" "}
          <h1>保存したイベント参加者のデータ(CSVファイル)の取り込み</h1>
          <input
            type="file"
            accept="application/json"
            onChange={handleFileRead}
          />
          <button
            className="backup-button"
            onClick={handleEventMemberFileUpload}
          >
            イベント参加者データをインポート
          </button>
        </div>
        <div className="buckupiport-field">
          {" "}
          <h1>保存した参加者のデータ(CSVファイル)の取り込み</h1>
          <input
            type="file"
            accept="application/json"
            onChange={handleFileRead}
          />{" "}
          <button className="backup-button" onClick={handleMemberFileUpload}>
            参加者データをインポート
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackUpImport;
