import React from "react";
import "./Datasend.scss";

const Datasend = () => {
  const formField = [
    { label: "お名前", name: "name" },
    { label: "おメール", name: "email" },
    { label: "おランキング", name: "rank" },
    { label: "人気だよ", name: "funs" },
  ];
  const Datasend = async (e) => {
    e.preventDefault();
    const formData = formField.reduce((acc, field) => {
      acc[field.name] = e.target[field.name].value;
      return acc;
    }, {});
    const { name, email, rank, funs } = formData;
    alert(name + email + rank + funs);
  };
  return (
    <>
      <div className="datasend-container">
        <div className="datasend-content">
          <div className="datasend-form">
            <form className="" onSubmit={Datasend}>
              {formField.map((field) => (
                <div key={field.name} className={`datasend-${field.name}`}>
                  <label className={`datasend-${field.name}-label`}>
                    {field.label}
                  </label>{" "}
                  <input
                    type="text"
                    name={field.name}
                    className="data-input"
                  ></input>
                </div>
              ))}

              <div className="datasend">
                <button className="signup-submit-button" type="submit">
                  送るで
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>あるで</div>
    </>
  );
};

export default Datasend;
