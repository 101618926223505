import axios from "axios";
import React, { useEffect, useState } from "react";

const TennisOffData = () => {
  const [eventData, setEventData] = useState(null);
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");

  const fetchEventData = async () => {
    try {
      const response = await axios.get(
        `http://127.0.0.1:5001/raising2024-7e234/us-central1/scrapeEvent`,
        {
          params: { url: url },
        }
      );
      setEventData(response.data);
      setError("");
      console.log(response.data);
    } catch (err) {
      setError("Error fetching event data");
    }
  };

  useEffect(() => {
    if (url) {
      fetchEventData();
    }
  }, [url]);

  return (
    <div>
      <input
        type="text"
        placeholder="Enter URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={fetchEventData}>Get Event Info</button>

      {error && <p>{error}</p>}

      {eventData && (
        <div>
          <h2>Event Info</h2>
          <p>
            <strong>Title:</strong> {eventData.title}
          </p>
          <p>
            <strong>Date:</strong> {eventData.date}
          </p>
          <p>
            <strong>定員:</strong> {eventData.numberOfapplicants}
          </p>
          <p>
            <strong>残り人数:</strong> {eventData.remainingNumber}
          </p>
        </div>
      )}
    </div>
  );
};

export default TennisOffData;
