// src/components/EventDetail.js
import { EventContext } from "Contexts/EventContext";
import { MemberContext } from "Contexts/MemberContext";
import { format, formatDate } from "date-fns";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ja from "date-fns/locale/ja";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import "./EventDetail.scss";
import { EventMembersContext } from "../Contexts/EventMembersContext";
import { EventDelete } from "../utils/EventDelete";
import { JointEvent } from "../utils/JointEvent";

const EventDetail = () => {
  const { eventList } = useContext(EventContext);
  const { currentUserInfo } = useContext(MemberContext);
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  // const [accountName, setAccountName] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [eventMembers, setEventMembers] = useState([]);
  const [userEventParticipation, setUserEventParticipation] = useState({});
  const [participantCounts, setParticipantCounts] = useState({});
  const { eventMembersList } = useContext(EventMembersContext);
  const [membersList, setMembersList] = useState([]);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [event, setEvent] = useState({
    title: "",
    site: "",
    site_region: "",
    starttime: "",
    duration: "",
    deadline: "",
    court_surface: "",
    court_count: "",
    court_num: "",
    capacity: "",
    map: "",
    detail: "",
    password: "",
    rank: "",
    created_at: "",
    updated_at: "",
  });
  const [administrator, setAdministrator] = useState(false);
  const isAdmin = currentUserInfo?.administrator === true;

  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    if (eventMembersList.length > 0) {
      setEventMembers(eventMembersList);
    }
  }, [eventMembersList]); // eventMembersListが更新されるたびに反映

  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const membersCollection = collection(db, "members");
        const snapshot = await getDocs(membersCollection);
        const membersList = snapshot.docs.map((doc) => {
          const { tel_num, birthday, realname, ...filteredData } = doc.data();
          return { id: doc.id, ...filteredData };
        });

        // membersList をステートにセット
        setMembersList(membersList);
        // administrator が true のメンバーをフィルタリング
        const adminmembers = Object.values(membersList).filter(
          (member) => member?.administrator === true
        );
        const adminEmailString = [
          ...new Set(adminmembers.map((member) => member.email)),
        ].join(", ");
      } catch (error) {
        console.error("Error fetching members data:", error);
      }
    };

    fetchMembersData();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user); // ユーザー情報を更新
      } else {
        setCurrentUser(null); // ユーザーがいない場合はnull
        alert("詳細の閲覧はログインしてからでお願いいたします");
        navigate("/eventlist"); // ログインページにリダイレクト
      }
    });

    // クリーンアップ処理
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const adminStatus = localStorage.getItem("administrator") === "true";
    setAdministrator(adminStatus);
  }, []);

  const convertDurationToMinutes = (duration) => {
    if (!duration) {
      return 0;
    }
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  useEffect(() => {
    if (
      !eventList ||
      eventList.length === 0 ||
      !membersList ||
      membersList.length === 0
    ) {
      return;
    }

    setEvents(eventList);

    const filteredEvents = eventList.filter((event) => event.id === id);
    if (filteredEvents.length > 0) {
      setEvent(filteredEvents[0]);
    }

    setEventMembers(eventMembersList);

    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      setCurrentUser(user);

      const userEventMembers = eventMembers.filter(
        (member) => member.memberId === userId
      );
      const userParticipation = userEventMembers.reduce((acc, member) => {
        acc[member.eventId] = true;
        return acc;
      }, {});
      setUserEventParticipation(userParticipation);

      const isUserMember = membersList.some(
        (member) => member.author.id === userId
      );
      if (!isUserMember) {
        alert(
          "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
        );
        navigate("/member");
      }
    }
  }, [id, navigate, eventList, membersList, eventMembers]);

  useEffect(() => {
    const fetchParticipantCounts = () => {
      const counts = events.reduce((acc, event) => {
        const participants = eventMembers.filter(
          (member) => member.eventId === event.id
        );
        acc[event.id] = participants.length;
        return acc;
      }, {});
      setParticipantCounts(counts);
    };
    fetchParticipantCounts();
  }, [events, eventMembers]);

  const handleJoinEvent = async (eventId) => {
    if (!currentUserInfo) {
      // alert("ログインしてください");
      // navigate("/login");
      return;
    }
    if (currentUserInfo.admin === false) {
      alert("管理者による認証がまだなされていません。");
      return;
    }

    await JointEvent(
      event,
      eventId,
      events,
      participantCounts,
      membersList,
      setEventMembers,
      setUserEventParticipation,
      setResponseMessage,
      currentUserInfo,
      currentUser,
      navigate
    );
  };

  const formatDateWithDayStyle = (date) => {
    if (!(date instanceof Date)) {
      console.error("Invalid date object:", date);
      return "無効な日付";
    }
    const formattedDate = format(date, "yyyy年M月d日（E）", { locale: ja });
    const day = format(date, "E", { locale: ja });

    let dayStyle = "default-text";
    if (day === "日") {
      dayStyle = "red-text";
    } else if (day === "土") {
      dayStyle = "blue-text";
    }

    return (
      <span className="default-text">
        {/* {formattedDate.split("（")[0]} */}
        <span className={dayStyle}>{`（${formattedDate.split("（")[1]}`}</span>
      </span>
    );
  };

  const getAccountName = (memberId) => {
    const member = membersList.find((m) => m.author.id === memberId);
    return member ? member.accountname : "不明";
  };

  const handleNavigateToCancel = async (eventId, memberId) => {
    if (isAdmin) {
      const account = getAccountName(memberId);
      if (!account) {
        console.error("Account name could not be found.");
        return;
      }
      console.log(account);
      navigate(`/eventcancel/${eventId}/${memberId}`);
    } else {
      alert("この操作は管理者のみが実行できます。");
    }
  };

  //イベントID　は　id
  //memberIdはuserから取得する

  const handleIndividualCancel = async (eventId) => {
    const memberId = currentUser.uid;
    try {
      const eventMembersQuery = query(
        collection(db, "event_members"),
        where("eventId", "==", eventId),
        where("memberId", "==", memberId)
      );
      const querySnapshot = await getDocs(eventMembersQuery);
      const eventMemberIds = [];
      querySnapshot.forEach((doc) => {
        eventMemberIds.push(doc.id); // FirestoreのドキュメントID
      });
      navigate(`/eventcancel/${eventId}/${eventMemberIds}`, {
        state: { cancelstate: true },
      });
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
      return [];
    }
  };

  const handleReturn = () => {
    navigate(`/eventlist`);
    return;
  };
  const handleReturnCalendaor = () => {
    navigate(`/calendar`);
    return;
  };

  // イベントに参加していないメンバーのアカウント名を取得

  const nonParticipants = membersList
    .filter(
      (member) =>
        !eventMembers.some(
          (eventMember) =>
            eventMember.memberId === member.author.id &&
            eventMember.eventId === event.id
        )
    )
    .map((member) => {
      const accountname = member.accountname || "不明";
      return (
        <button
          key={member.author.id}
          className="event-member-management-non-participant-button"
          onClick={() => handleAddMember(event.id, member.author.id)}
          disabled={!isAdmin}
        >
          {accountname}
        </button>
      );
    });

  const handleAddMember = async (eventId, memberId) => {
    // イベント情報を取得
    const event = events.find((e) => e.id === eventId);
    if (!event) {
      alert("イベントが見つかりません");
      return;
    }

    // 現在の参加者数を取得
    const currentParticipantsCount = eventMembers.filter(
      (member) => member.eventId === eventId
    ).length;

    // 定員を超えているか確認
    if (currentParticipantsCount >= event.capacity) {
      alert("定員に達しています。");
      return;
    }

    const authorIds = membersList.map((item) => item.author.id);

    console.log(authorIds);
    // メンバー情報を取得
    const member = membersList.find((m) => m.author.id === memberId);
    if (!member) {
      alert("メンバー情報が見つかりません");
      return;
    }

    // メンバーのアカウント名を取得
    const accountName = member.accountname || "不明";

    // 参加確認ダイアログを表示
    const confirmAdd = window.confirm(`${accountName}さんを参加させますか？`);
    if (!confirmAdd) return;

    // メンバーのアカウント名を取得
    const user = membersList.find((member) => member.author.id === memberId);
    if (!user) {
      alert("メンバー情報が見つかりません");
      return;
    }
    const accountname = user.accountname;

    // イベントメンバーに新しいドキュメントを追加
    await addDoc(collection(db, "event_members"), {
      eventId: eventId,
      memberId: memberId,
    });

    // ローカルのステートを更新
    setEventMembers((prevEventMembers) => [
      ...prevEventMembers,
      { eventId, memberId, accountname },
    ]);

    setMessage(""); // メッセージをクリア
  };

  useEffect(() => {}, [event]);

  const showTennisOff = () => {
    navigate("../tennisoff");
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: checked,
    }));
  };

  const handleeventDelete = async (eventId) => {
    try {
      // バッチ処理を開始
      const batch = writeBatch(db);

      // events コレクションから該当するドキュメントを削除
      const eventRef = doc(db, "events", eventId);
      batch.delete(eventRef);

      // event_members コレクションから該当するドキュメントを検索して削除
      const eventMembersQuery = query(
        collection(db, "event_members"),
        where("eventId", "==", eventId)
      );
      const eventMembersSnapshot = await getDocs(eventMembersQuery);

      eventMembersSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // バッチをコミット
      await batch.commit();
      console.log("イベントと関連する参加者情報が削除されました");
    } catch (error) {
      console.error("削除中にエラーが発生しました:", error);
    }
  };

  /**
   * テニスオフで募集することを登録
   */
  const handleTennisOff = async () => {
    const {
      starttime_date,
      starttime_hour,
      starttime_minute,
      duration_hour,
      duration_minute,
      deadline_hours_before,
    } = event;
    const starttime = `${starttime_date}T${starttime_hour}:${starttime_minute}:00+09:00`; // 日本時間を指定
    const duration = `${duration_hour}:${duration_minute}`;

    try {
      await updateDoc(doc(db, "events", id), {
        ...event,
        tennis_off: true,
        updated_at: new Date().toISOString(),
        starttime,
        duration,
      });
      return;
    } catch (error) {
      console.error("イベントの更新中にエラーが発生しました:", error);
    }
  };

  const handleTennisOffStop = async () => {
    const {
      starttime_date,
      starttime_hour,
      starttime_minute,
      duration_hour,
      duration_minute,
      deadline_hours_before,
    } = event;
    const starttime = `${starttime_date}T${starttime_hour}:${starttime_minute}:00+09:00`; // 日本時間を指定
    const duration = `${duration_hour}:${duration_minute}`;

    try {
      await updateDoc(doc(db, "events", id), {
        ...event,
        tennis_off: false,
        updated_at: new Date().toISOString(),
        starttime,
        duration,
      });
      return;
    } catch (error) {
      console.error("イベントの更新中にエラーが発生しました:", error);
    }
  };

  // setTimeout(() => {
  //   navigate(`/calendar`);
  // }, 30000);

  return (
    <>
      <div className="eventdetail-container">
        <h1> {event.title}</h1>
        <div className="closed_button">
          <div className="eventdetail-eventlist">
            <button
              onClick={() => {
                navigate(`/eventlist`);
              }}
            >
              eventlist
            </button>
          </div>
          <img
            src={"/images/calendar.png"}
            alt="カレンダー"
            className="eventdetail-calendar-image"
            onClick={() => {
              navigate(`/calendar`);
            }}
          ></img>
          <button
            className="eventdetail-close-button-left"
            onClick={() => {
              navigate("/eventlist");
            }}
          >
            ×
          </button>
        </div>

        <table
          className={`eventdetail-table table_layout ${
            isAdmin ? "" : "member"
          }`}
        >
          <tbody>
            <tr>
              <td className="event_schedule">開催日時</td>
              <td className="event_schedule_content">
                {event.starttime && (
                  <>
                    {format(event.starttime, "y年M月d日")}（
                    <span
                      style={{
                        color:
                          format(event.starttime, "E", { locale: ja }) === "土"
                            ? "blue"
                            : format(event.starttime, "E", { locale: ja }) ===
                              "日"
                            ? "red"
                            : "black",
                      }}
                    >
                      {format(event.starttime, "E", { locale: ja })}
                    </span>
                    ）{format(event.starttime, "a") === "am" ? "午後" : "午前"}
                    {format(event.starttime, "h")}時
                    {format(event.starttime, "m") !== "0" && (
                      <>{format(event.starttime, "m")}分</>
                    )}
                  </>
                )}
                から
                {event.duration_hour && (
                  <>{parseInt(event.duration_hour, 10)}時間</>
                )}
              </td>
            </tr>
            {/* <tr>
              <td className="event_deadtime">締切時間</td>
              <td className="event_deadtime_content"> */}
            {/* {formattedDate.split("(")[0]} */}
            {/* <span style={weekdayStyle}> ( {dayOfWeek} ) </span> */}
            {/* {formattedDate.split(")")[1].replace(/\s+/g, "")} */}
            {/* </td> */}
            {/* </tr> */}
            <tr>
              <td className="event_site">会場</td>
              <td className="event_site_content">
                {event.site}（{event.site_region}）
              </td>
            </tr>
            <tr>
              <td className="event_map">会場地図</td>
              <td className="event_map_content">
                {event.map ? (
                  <a href={event.map} target="_blank" rel="noopener noreferrer">
                    地図を見る
                  </a>
                ) : (
                  "地図情報がありません"
                )}
              </td>
            </tr>
            <tr>
              <td className="event_court">コート</td>
              <td className="event_court_content">
                {event.court_count}面　種類：
                {event.court_surface}
                　コート番号
                {event.court_num}
              </td>
            </tr>
            <tr>
              <td className="event_capacity">定員</td>
              <td className="event_capacity_content">{event.capacity}</td>
            </tr>
            <tr>
              <td className="event_biko">備考</td>
              <td className="event_biko_content">{event.detail}</td>
            </tr>
            <tr>
              <td className="event_paticiant">参加予定</td>
              <td className="event_paticiant_content">
                <div className="participants-container">
                  {eventMembers
                    .filter((member) => member.eventId === event.id)
                    .map((member) => (
                      <div key={member.id}>
                        <button
                          onClick={() =>
                            handleNavigateToCancel(event.id, member.id)
                          }
                          style={{
                            cursor: administrator ? "pointer" : "default",
                          }}
                          disabled={!isAdmin}
                        >
                          {getAccountName(member.memberId)}{" "}
                        </button>
                      </div>
                    ))}
                </div>
              </td>
            </tr>
            {isAdmin && (
              <tr>
                <td className="non_participants ">未参加メンバー</td>
                <td className="non_participants_content">
                  {nonParticipants.length > 0 ? nonParticipants : "なし"}
                </td>
              </tr>
            )}

            <tr style={{ borderRight: "solid 1px black" }}>
              <td className="event_apply">参加申込</td>
              <td className="event_apply_content">
                {participantCounts[event.id] >= event.capacity &&
                !userEventParticipation[event.id] ? (
                  <button className="eventdetail-closed-button" disabled>
                    定員締切
                  </button>
                ) : userEventParticipation[event.id] ||
                  participantCounts[event.id] >= event.capacity ? (
                  <>
                    <button
                      className="eventdetail-paticipants-button"
                      onClick={() => handleJoinEvent(event.id)}
                    >
                      参加予定
                    </button>
                    <button
                      className="eventdetail-cancel-button"
                      onClick={() => handleIndividualCancel(event.id)}
                    >
                      キャンセル
                    </button>
                  </>
                ) : (
                  <button
                    className="eventdetail-recruit-button"
                    onClick={() => handleJoinEvent(event.id)}
                  >
                    募集中
                  </button>
                )}
              </td>
            </tr>
            {event.tennis_off && (
              <tr>
                <td>テニスオフ</td>
                <td
                  className="eventdetail-tennis-off"
                  style={{ color: "#382891" }}
                  onClick={showTennisOff}
                >
                  テニスオフで募集中です
                </td>
              </tr>
            )}
            {isAdmin && (
              <tr>
                <td>イベント管理</td>
                <>
                  <td>
                    {event.tennis_off ? (
                      <button
                        className="eventdetail-tennisoff-button"
                        onClick={() => handleTennisOffStop(event.id)}
                      >
                        テニスオフ募集を停止
                      </button>
                    ) : (
                      <button
                        className="eventdetail-tennisoff-button"
                        onClick={() => handleTennisOff(event.id)}
                      >
                        テニスオフで募集する
                      </button>
                    )}
                    <button
                      className="eventdetail-delete-button"
                      onClick={() => handleeventDelete(event.id)}
                    >
                      削除
                    </button>

                    <button
                      className="eventdetail-edit-button"
                      onClick={() => navigate(`/eventinfo/${event.id}/edit`)}
                    >
                      編集
                    </button>
                    <button
                      className="eventdetail-mail-button"
                      onClick={() =>
                        navigate(`/paticipantemailsender/${event.id}`)
                      }
                    >
                      参加者にメールを送る
                    </button>
                  </td>
                </>
              </tr>
            )}
          </tbody>
        </table>

        <div className="eventdetail-return">
          <button
            className="eventdetail-return-button"
            onClick={handleReturnCalendaor}
          >
            カレンダーへ
          </button>
          <button className="eventdetail-return-button" onClick={handleReturn}>
            一覧へ
          </button>
        </div>
      </div>
    </>
  );
};

export default EventDetail;
