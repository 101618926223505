import React from "react";

export const EmailSenderBase = async (
  selectedEmail,
  subject,
  text,
  setResponseMessage
) => {
  try {
    if (selectedEmail.length === 0) {
      // setResponseMessage("少なくとも1つのメールアドレスを選択してください。");
      return;
    }

    // メール送信のためのPOSTリクエスト
    const response = await fetch(
      "https://us-central1-raising2024-7e234.cloudfunctions.net/sendEmail",
      // "http://127.0.0.1:5001/raising2024-7e234/us-central1/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: selectedEmail.join(", "), // 選択したメールアドレスをカンマ区切りで送信
          subject,
          text,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("メール送信に失敗しました");
    }

    const data = await response.json();
    // setResponseMessage(data.message); // 成功メッセージ
  } catch (error) {
    console.error("エラー:", error);
    // setResponseMessage("メール送信中にエラーが発生しました。");
  }
};
