export const endTimeCalclater = (eventId, eventList) => {
  if (eventList.length > 0) {
    //eventList が空でない場合のみ処理を実行
    const eventFound = eventList.find((event) => event.id === eventId);
    if (eventFound) {
      //開始時間に分を加算;
      if (eventFound.starttime && eventFound.duration) {
        const startTime = new Date(eventFound.starttime); // starttime を Date オブジェクトに変換
        const durationMinutes = eventFound.duration; // durationtime を数値に変換
        //分を加算して新しい時間を取得;
        const newEndTime = new Date(
          startTime.getTime() + durationMinutes * 60 * 1000
        );
        return newEndTime;
      }
    }
  }
  return null;
  // ここでnumberになることを確認
};
