import React, { useEffect, useState } from "react";
import "./SentEmails.scss"; // CSSファイルをインポート
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const SentEmails = () => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const emailList = await getSentEmails();

        // 日付でソート（降順）
        const sortedEmails = emailList.sort((a, b) => {
          return b.timestamp.seconds - a.timestamp.seconds;
        });

        setEmails(sortedEmails);
      } catch (error) {
        console.error("Error fetching emails: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
  }, []);

  //送信済みメールの取得
  const getSentEmails = async () => {
    const emailsCol = collection(db, "emailRecords");
    const emailSnapshot = await getDocs(emailsCol);
    const emailList = emailSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return emailList;
  };

  // 送信済みメールの削除
  const deleteEmail = async (emailId) => {
    try {
      await deleteDoc(doc(db, "emailRecords", emailId));
    } catch (error) {
      console.error("Error deleting email:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteEmail(id);
      setEmails((prevEmails) => prevEmails.filter((email) => email.id !== id));
    } catch (error) {
      console.error("Error deleting email:", error);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div>
      <h1>Sent Emails</h1>
      <table>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Subject</th>
            <th>Recipients</th>
            <th>Content</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {emails.map((email) => (
            <tr key={email.id}>
              <td>
                {new Date(email.timestamp.seconds * 1000).toLocaleString()}
              </td>
              <td>{email.subject}</td>
              <td>
                <ul>
                  {email.recipients.map((recipient, index) => (
                    <li key={index}>{recipient}</li>
                  ))}
                </ul>
              </td>
              <td>{email.content}</td>
              <td>
                <button onClick={() => handleDelete(email.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SentEmails;
