import "./Links.scss";
import { useNavigate } from "react-router-dom";

const Links = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="link-container">
        <div className="link-content">
          <div className="links-label">リンク集</div>
          <div className="links-content">
            <a href="http://joyotennis.com/">城陽テニス協会</a>
            <a href="https://www1.city.joyo.kyoto.jp/stagia/reserve/gin_menu">
              城陽市施設予約
            </a>
            <a
              onClick={() => navigate(`/tennisoff`)}
              style={{
                textDecoration: "underline",
                textDecorationColor: "white", // 下線の色を黒にする
                textUnderlineOffset: "2px", // 下線の位置を調整（例：3px）
              }}
            >
              テニスオフでの募集状況
            </a>
            <a href="https://tennis365.net/">テニス３６５</a>
            <a href="https://kc-ta.com/match/">京都市テニス協会</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Links;
