import React, { useState } from "react";
import Papa from "papaparse";

const JsonToCsv = () => {
  const [jsonData, setJsonData] = useState(null);

  // ファイルを読み込む
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = JSON.parse(e.target.result);
          setJsonData(data);
        } catch (error) {
          alert("JSONファイルの形式が正しくありません");
        }
      };
      reader.readAsText(file);
    }
  };

  // CSVを生成してダウンロードする
  const downloadCsv = (data, filename) => {
    const csv = Papa.unparse(data);
    // UTF-8 BOM付きでCSVを生成
    const blob = new Blob([`\uFEFF${csv}`], {
      type: "text/csv;charset=utf-8-sig;",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // 各データのダウンロードボタンを生成
  const flattenData = (data) => {
    return data.map((item) => {
      // `author` オブジェクトがあればそのプロパティを展開
      if (item.author) {
        item.author_username = item.author.username;
        item.author_id = item.author.id;
        delete item.author; // `author` オブジェクトを削除
      }
      return item;
    });
  };

  const handleDownload = (key) => {
    if (jsonData && jsonData[key]) {
      // JSON文字列をオブジェクトに変換
      const parsedData = JSON.parse(jsonData[key]);

      // `author` オブジェクトを展開
      const flattenedData = flattenData(parsedData);

      downloadCsv(flattenedData, `${key}.csv`);
    } else {
      alert(`${key}のデータが見つかりません`);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>JSON to CSV Converter</h1>
      <input
        type="file"
        accept="application/json"
        onChange={handleFileUpload}
        style={{ marginBottom: "20px" }}
      />
      {jsonData && (
        <div>
          <button onClick={() => handleDownload("events")}>
            Download Events CSV
          </button>
          <button onClick={() => handleDownload("members")}>
            Download Members CSV
          </button>
          <button onClick={() => handleDownload("eventMembers")}>
            Download Event Members CSV
          </button>
        </div>
      )}
    </div>
  );
};

export default JsonToCsv;
