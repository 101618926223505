import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import EmailSender from "./Components/EmailSender";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import SignUpForm from "./Components/SignUpForm";
import Datasend from "./Components/Datasend";
import Login from "./Components/Login";
import { AuthProvider } from "./Contexts/AuthContext";
import EmailLogin from "./Components/EmailLogin";
import ResetEmailPassword from "./Components/ResetEmailPassword";
import EmailChange from "./Components/EmailChange";
import Logout from "./Components/Logout";
import MemberList from "./Components/MemberList";
// import RegistEmailSender from "./Components/EmailComponents/RegistEmailSender";
import { MemberProvider } from "Contexts/MemberContext";
import MemberInfo from "./Components/MemberInfo";
import EmailSenderSelect from "./Components/EmailSenderSelect";
import Management from "./Components/Management";
// import EventCreate from "./Components/EventCreate";
import { EventProvider } from "./Contexts/EventContext";
import EventList from "./Components/EventList";
import { EventMembersProvider } from "./Contexts/EventMembersContext";
import EventSelect from "./Components/EventSelect";
import EventInfo from "./Components/EventInfo";
import EventDetail from "./Components/EventDetail";
import Calendar from "./Components/Calendar";
import EventCancel from "./Components/EventCancel";
import Links from "./Components/Links";
import Testdayo from "./Components/Testdayo";
import PaticipantEmaiSender from "./Components/PaticipantEmaiSender";
import SentEmails from "./Components/SentEmails";
import BackUpImport from "./utils/BackUpImport";
import JsonToCsv from "./utils/JsonToCsv";
import TennisOff from "./Components/TennisOff";

function App() {
  return (
    <Router>
      {" "}
      <AuthProvider>
        <EventMembersProvider>
          <EventProvider>
            <MemberProvider>
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="management" element={<Management />} />
                <Route path="backupimport" element={<BackUpImport />} />
                <Route path="jsontocsv" element={<JsonToCsv />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="emaillogin" element={<EmailLogin />} />{" "}
                <Route path="links" element={<Links />} />{" "}
                <Route path="tennisoff" element={<TennisOff />} />{" "}
                {/* <Route path="membercreate" element={<MemberCreate />} /> */}
                {/* <Route path="registemailsender" element={<RegistEmailSender />} /> */}
                <Route path="resetpassword" element={<ResetEmailPassword />} />
                <Route path="emailchange" element={<EmailChange />} />
                <Route
                  path="emailsenderselect"
                  element={<EmailSenderSelect />}
                />
                <Route path="sentemails" element={<SentEmails />} />
                <Route path="emailsender" element={<EmailSender />} />
                <Route path="signupform" element={<SignUpForm />} />
                <Route path="memberlist" element={<MemberList />} />
                <Route path="memberinfo/:id" element={<MemberInfo />} />
                <Route path="memberinfo" element={<MemberInfo />} />
                {/* <Route path="" element={<EventCreate />} />
                <Route path="eventcreate/:id" element={<EventCreate />} /> */}
                <Route path="eventlist" element={<EventList />} />
                <Route path="eventselect" element={<EventSelect />} />
                <Route path="eventinfo" element={<EventInfo />} />
                <Route
                  path="eventinfo/:eventId/:eventState"
                  element={<EventInfo />}
                />
                <Route path="eventinfo/:eventId" element={<EventInfo />} />
                <Route path="eventdetail/:id" element={<EventDetail />} />
                <Route path="eventdetail" element={<EventDetail />} />
                <Route
                  path="/eventcancel/:eventId/:eventMemberId"
                  element={<EventCancel />}
                />
                <Route
                  path="/paticipantemailsender/:id"
                  element={<PaticipantEmaiSender />}
                />
                <Route path="calendar" element={<Calendar />} />
                {/* 実装していない。 */}{" "}
                <Route path="email" element={<EmailSender />} />
                <Route path="datasend" element={<Datasend />} />
                <Route path="test" element={<Testdayo />} />
                <Route path="test/:eventId" element={<Testdayo />} />
              </Routes>
            </MemberProvider>
          </EventProvider>
        </EventMembersProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
