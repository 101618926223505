export const findMostFutureEvent = (
  events,
  eventMembersList,
  userId,
  isAfter
) => {
  // ユーザーが参加しているイベントのリストを取得
  const userEventMembers = eventMembersList.filter(
    (member) => member.memberId === userId
  );

  // ユーザーが参加しているイベントIDのリスト
  const userEventIds = userEventMembers.map((member) => member.eventId);

  // ユーザーが参加しているイベントをフィルタリング
  const userEvents = events.filter((event) => userEventIds.includes(event.id));

  // 最も未来のイベントを取得
  return userEvents.reduce((latest, event) => {
    return !latest ||
      isAfter(new Date(event.starttime), new Date(latest.starttime))
      ? event
      : latest;
  }, null);
};
