import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import { MemberContext } from "../Contexts/MemberContext";

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);
  const navigate = useNavigate();
  // const [isAdmin, setIsAdmin] = useState(false);
  const { currentUserInfo } = useContext(MemberContext);
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };
  // useEffect(() => {
  //   setIsAdmin(currentUserInfo.administrator);
  // }, [currentUserInfo]);

  const isAdmin = currentUserInfo?.administrator === true;

  const closeMenu = () => {
    setMenuActive(false);
  };

  return (
    <nav className="navbar">
      <div className="circleName">ライジング</div>
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776; {/* ハンバーガーアイコン */}
      </div>
      <ul className={`nav-links ${menuActive ? "active" : ""}`}>
        {isAdmin && (
          <li onClick={closeMenu}>
            <Link to="/management">管理</Link>
          </li>
        )}
        <li onClick={closeMenu}>
          <Link to="/memberlist">メンバー一覧</Link>
        </li>
        {isAdmin && (
          <li onClick={closeMenu}>
            <Link to="/test">テスト</Link>
          </li>
        )}
        <li onClick={closeMenu}>
          <Link to="/calendar">カレンダー</Link>
        </li>
        <li onClick={closeMenu}>
          <Link to="/eventlist">イベント一覧</Link>
        </li>
        <li onClick={closeMenu}>
          <Link to="/">ホーム</Link>
        </li>
        {isAdmin && (
          <li onClick={closeMenu}>
            <Link to="/emailsenderselect">メール送信</Link>
          </li>
        )}
        <li onClick={closeMenu}>
          <Link to="/links">リンク</Link>
        </li>
        {!currentUserInfo ? (
          <li onClick={closeMenu}>
            <Link to="/login">ログイン</Link>
          </li>
        ) : (
          <>
            <li onClick={closeMenu}>
              <Link to="/logout">ログアウト</Link>
            </li>
          </>
        )}
      </ul>
      <div
        className={`account-name ${!currentUserInfo ? "logged-out" : ""}`}
        onClick={closeMenu}
      >
        {!currentUserInfo ? (
          <span>ログインしてください</span>
        ) : (
          <span
            className="navbar-accountname"
            onClick={() =>
              navigate(`/memberedit/${currentUserInfo?.author.id}`)
            }
          >
            会員名：
            {currentUserInfo ? currentUserInfo.accountname : "ゲスト"}
          </span>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
