import { useContext, useEffect, useRef, useState } from "react";
import "./EmailSenderSelect.scss";
import { MemberContext } from "../Contexts/MemberContext";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";

function EmailSenderSelect() {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const [membersList, setMembersList] = useState([]);
  const [uniquemembersEmail, setUniquemembersEmail] = useState({});
  const [allSelectedAddresses, setAllSelectedAddresses] = useState(true);
  const { currentUserInfo } = useContext(MemberContext);
  // const [isAllSelected, setIsAllSelected] = useState(true);
  const inputRefs = [useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();

  //管理者認証者のみ閲覧するよう設定
  useEffect(() => {
    // const isCurrentUserAdmin = currentUserInfo?.administrator === true;
    console.log(currentUserInfo);
    if (currentUserInfo?.administrator) {
      console.log("カレントユーザ情報を取得しました");
    } else {
      console.log("あかんで");
      navigate("/login");
      return;
    }
  }, []);

  //データベースからデータを取得
  const fetchMembersData = async (setMembersList) => {
    try {
      const membersCollection = collection(db, "members");
      const snapshot = await getDocs(membersCollection);
      const membersList = snapshot.docs.map((doc) => {
        const { tel_num, birthday, realname, ...filteredData } = doc.data();
        return { id: doc.id, ...filteredData };
      });

      setMembersList(membersList); // データをステートにセット
    } catch (error) {
      console.error("Error fetching members data:", error);
    }
  };

  // 初回レンダリング時にデータを取得
  useEffect(() => {
    fetchMembersData(setMembersList); // ステート設定関数を渡す
  }, []);

  //メールアドレスがある会員情報を取得（重複処理も実施）
  const membersWithEmail = membersList.filter(
    (member) => member?.email.trim() !== ""
  );

  //上記からメールアドレスを抽出
  const membersEmailOmit = membersWithEmail.map((member) => member.email);
  useEffect(() => {
    //上記のリストにあるアドレスの重複処理
    const uniqueEmail = [...new Set(membersEmailOmit)];
    setUniquemembersEmail(uniqueEmail);
  }, [membersList]);

  //アドレスの全選択のチェックボックスの操作
  const handleCheckboxChangeAllAdress = () => {
    setSelectedAddresses((prevState) => {
      // `uniquemembersEmail` が配列であることを確認
      if (!Array.isArray(uniquemembersEmail)) {
        console.error(
          "uniquemembersEmail is not an array:",
          uniquemembersEmail
        );
        return prevState; // 現在の状態をそのまま返す
      }

      const newState = {};
      uniquemembersEmail.forEach((email) => {
        newState[email] = !allSelectedAddresses; // `true` または `false` を設定
      });
      console.log(uniquemembersEmail);
      return newState;
    });

    setAllSelectedAddresses((prev) => !prev);
  };

  // チェックボックスが全選択されているかどうかを判定;
  const isAllSelected = Object.values(uniquemembersEmail).every(
    (email) => selectedAddresses[email] === true
  );

  // 個別選択・解除
  const handleCheckboxChange = (email) => {
    setSelectedAddresses((prevState) => ({
      ...prevState,
      [email]: !prevState[email], // 選択状態をトグル
    }));
  };

  // individual checkboxのcheckedを制御
  const isChecked = (email) => selectedAddresses[email] || false;

  // メール送信のためのフォーム送信
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // 選択されたアドレスを抽出して送信
      const selectedEmails = Object.entries(selectedAddresses)
        .filter(([email, isSelected]) => isSelected)
        .map(([email]) => email);

      if (selectedEmails.length === 0) {
        setResponseMessage("少なくとも1つのメールアドレスを選択してください。");
        return;
      }

      // メール送信のためのPOSTリクエスト
      const response = await fetch(
        "https://us-central1-raising2024-7e234.cloudfunctions.net/sendEmail",
        // "http://127.0.0.1:5001/raising2024-7e234/us-central1/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: selectedEmails.join(", "), // 選択したメールアドレスをカンマ区切りで送信
            subject,
            text,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("メール送信に失敗しました");
      }

      const data = await response.json();
      setResponseMessage(data.message); // 成功メッセージ

      // メール送信成功後にFirestoreに情報を追加
      await addDoc(collection(db, "emailRecords"), {
        subject: subject,
        content: text,
        recipients: selectedEmails,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error("エラー:", error);
      setResponseMessage("メール送信中にエラーが発生しました。");
    }

    setTimeout(() => {
      navigate(`/eventlist`);
    }, 5000);
  };

  // 入力欄にフォーカスを当てる
  const handleMouseEnter = (index) => {
    if (inputRefs[index].current) {
      inputRefs[index].current.focus();
    }
  };

  // Enterキーで次の入力欄にフォーカスを移動
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault(); // フォーム送信を防ぐ
      if (inputRefs[index + 1]?.current) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  return (
    <div className="email-select-container">
      <div className="email-select-content">
        {/* <h1 className="email-select-title">メール送信</h1> */}

        <form className="email-select-form" onSubmit={handleSubmit}>
          <div className="email-select-field">
            <div className="email-select-checkd">
              {" "}
              <label
                className="email-select-label"
                onMouseEnter={() => handleMouseEnter(0)}
              >
                メールアドレス選択
              </label>
              <div className="email-select-all">
                全選択{" "}
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  checked={isAllSelected}
                  onChange={handleCheckboxChangeAllAdress}
                />
              </div>
              <div className="email-select-none"></div>
            </div>
            {membersWithEmail.map((member) => (
              <>
                {" "}
                <div className="email-select-filed-inner">
                  <div className="email-select-checkbox-list">
                    <div
                      key={member.email}
                      className="email-select-checkbox-item"
                    >
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedAddresses[member.email] || false}
                        onChange={() => handleCheckboxChange(member.email)}
                      />
                    </div>
                    <div className="email-select-accountname">
                      {member.accountname}
                    </div>
                    <div className="email-select-adress">{member.email}</div>
                  </div>
                </div>
              </>
            ))}
          </div>

          <div className="email-select-field">
            <label
              className="email-select-label"
              onMouseEnter={() => handleMouseEnter(1)}
            >
              件　名
            </label>
            <input
              ref={inputRefs[0]}
              className="email-select-input"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              onMouseEnter={() => handleMouseEnter(1)}
              onKeyDown={(e) => handleKeyDown(e, 1)} // Enterキーが押された時の処理
            />
          </div>

          <div className="email-select-field">
            <label
              className="email-select-label"
              onMouseEnter={() => handleMouseEnter(2)}
            >
              本　文
            </label>
            <textarea
              ref={inputRefs[1]}
              className="email-select-textarea"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
              onMouseEnter={() => handleMouseEnter(2)}
            />
          </div>

          <button className="email-select-button" type="submit">
            メール送信
          </button>
        </form>

        {responseMessage && (
          <h2 className="email-select-response">{responseMessage}</h2>
        )}
      </div>
    </div>
  );
}

export default EmailSenderSelect;
