import { useContext, useEffect, useState } from "react";
import { MemberContext } from "../Contexts/MemberContext";
import { useNavigate } from "react-router-dom";
import "./MemberList.scss";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

const MemberList = () => {
  const navigate = useNavigate();
  const { currentUserInfo } = useContext(MemberContext);
  const isAdmin = currentUserInfo?.administrator === true;
  const [isLoading, setIsLoading] = useState(false);
  const [showNonMember, setShowNonMember] = useState(false);
  const [membersList, setMembersList] = useState(false);

  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const membersCollection = collection(db, "members");
        const snapshot = await getDocs(membersCollection);
        const membersList = snapshot.docs.map((doc) => {
          const { ...filteredData } = doc.data();
          return { id: doc.id, ...filteredData };
        });

        // membersList をステートにセット
        setMembersList(membersList);
        // administrator が true のメンバーをフィルタリング
      } catch (error) {
        console.error("Error fetching members data:", error);
      }
    };

    fetchMembersData();
  }, []);

  //管理者認証者のみ閲覧するよう設定
  //遷移時はcurrentUserInfoから、再表示時はセッションから取得と思われる。
  //軽くループしている状態で、効率化の検討が必要
  useEffect(() => {
    const savedUserInfo = JSON.parse(sessionStorage.getItem("currentUserInfo"));

    // currentUserInfo が null または admin プロパティが undefined でないことを確認
    const isCurrentUserAdmin = currentUserInfo?.admin === true;
    console.log(isCurrentUserAdmin);
    if (!isCurrentUserAdmin) {
      setIsLoading(true);
      alert("メンバー一覧は会員認証後に閲覧できます");
      navigate("../login");
      setIsLoading(false); // ログインページに遷移
    } else {
      // if (savedUserInfo) {
      //   console.log("セッション情報を取得しました");
      // }
      // // admin でない場合、リダイレクト
      // else {
      // }
    }
  }, [currentUserInfo, navigate]);

  const handleButtonClick = (id) => {
    if (
      (currentUserInfo && currentUserInfo.author.id === id) ||
      currentUserInfo?.administrator
    ) {
      navigate(`/memberinfo`, { state: { authorId: id } });
    } else {
      alert("詳細は本人と管理者だけが閲覧や編集できます。");
    }
  };

  // 削除ボタン
  const handleDeleteButtonClick = async (id) => {
    const member = membersList.find((member) => member.author.id === id);
    if (!member) {
      alert("該当する会員が見つかりませんでした。");
      return;
    }

    const confirmDelete = window.confirm(
      `本当に${member.accountname}を削除しますか？`
    );
    if (!confirmDelete) return;

    try {
      // Firestoreから削除
      await deleteDoc(doc(db, "members", member.id));

      // イベント参加情報を削除
      const eventMembersQuery = query(
        collection(db, "event_members"),
        where("memberId", "==", id)
      );
      const eventMembersSnapshot = await getDocs(eventMembersQuery);

      if (!eventMembersSnapshot.empty) {
        for (const docSnap of eventMembersSnapshot.docs) {
          await deleteDoc(docSnap.ref);
        }
      }

      // メンバーリストを更新
      const updatedMembersList = Object.values(membersList).filter(
        (m) => m.author.id !== id
      );
      setMembersList(updatedMembersList);
    } catch (error) {
      console.error("削除に失敗しました:", error);
    }
  };

  //メンバー一覧の表示から会員外登録した人の表示をしないようにします。
  const memberOnlyList = Object.values(membersList).filter(
    (member) => !member.author.id.includes("nonMember")
  );

  const nonMemberList = Object.values(membersList).filter((member) =>
    member.author.id.includes("nonMember")
  );

  // チェックボックスの変更ハンドラー
  const handleCheckboxChange = () => {
    setShowNonMember(!showNonMember); // チェックボックスの状態をトグル
  };

  return (
    <div className="memberlist-container">
      {isLoading && (
        <div className="overlay">
          <div className="loading-message">処理中...</div>
        </div>
      )}
      <div className="memberlist-field">
        <table className={`table_layout ${isAdmin ? "" : "member"}`}>
          <thead>
            <tr>
              {/* {isAdmin ? <th className="name hide-on-narrow">お名前</th> : ""} */}
              <th className="accountname">アカウント名</th>
              {isAdmin ? (
                <th
                  className="email"
                  style={{
                    width: "250px",
                  }}
                >
                  メールアドレス
                </th>
              ) : (
                ""
              )}{" "}
              {isAdmin ? (
                <th className="phone hide-on-narrow">携帯番号</th>
              ) : (
                ""
              )}{" "}
              {isAdmin ? (
                <th className="admin hide-on-narrow">参加承認</th>
              ) : (
                ""
              )}{" "}
              {isAdmin ? (
                <th className="administrator hide-on-narrow">管理権限</th>
              ) : (
                ""
              )}{" "}
              <th
                className="detail"
                style={{
                  width: "30px",
                }}
              >
                会員詳細
              </th>
              {isAdmin ? (
                <th className="delete hide-on-narrow">会員削除</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {memberOnlyList.map((member, index) => (
              <tr key={member.id}>
                {/* {isAdmin ? (
                  <td className="member_name hide-on-narrow">
                    {member.realname}
                  </td>
                ) : (
                  ""
                )} */}
                <td className="member_accountname">{member.accountname}</td>
                {isAdmin ? (
                  <td className="member_email">{member.email}</td>
                ) : (
                  ""
                )}
                {isAdmin ? (
                  <td className="member_tel_num hide-on-narrow">
                    {member.tel_num}
                  </td>
                ) : (
                  ""
                )}
                {isAdmin ? (
                  <td className="member_admin hide-on-narrow">
                    {member.admin ? "■" : "□"}
                  </td>
                ) : (
                  ""
                )}
                {isAdmin ? (
                  <td className="member_adminstrator hide-on-narrow">
                    {member.administrator ? "■" : "□"}
                  </td>
                ) : (
                  ""
                )}
                <td className="member_detail">
                  <button
                    className="memberlist-detail-button"
                    onClick={() => handleButtonClick(member.author.id)}
                  >
                    表示
                  </button>
                </td>
                {isAdmin ? (
                  <td className="member_delete hide-on-narrow">
                    <button
                      className="memberlist-delete-button"
                      onClick={() => handleDeleteButtonClick(member.author.id)}
                    >
                      削除
                    </button>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}

            <tr>
              {isAdmin ? (
                <td colSpan={8} className="nonMemberShow">
                  <div className="nonMemberShow-button">
                    <label>会員外を表示</label>
                    <input
                      type="checkbox"
                      checked={showNonMember}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </td>
              ) : (
                ""
              )}
            </tr>

            {showNonMember &&
              nonMemberList.map((member, index) => (
                <tr key={member.id}>
                  {isAdmin ? (
                    <td className="member_name hide-on-narrow">
                      {member.realname}
                    </td>
                  ) : (
                    ""
                  )}
                  <td className="member_accountname">{member.accountname}</td>
                  <td className="member_email">{member.email}</td>
                  {isAdmin ? (
                    <td className="member_tel_num hide-on-narrow">
                      {member.tel_num}
                    </td>
                  ) : (
                    ""
                  )}
                  {isAdmin ? (
                    <td className="member_admin hide-on-narrow">
                      {member.admin ? "■" : "□"}
                    </td>
                  ) : (
                    ""
                  )}
                  {isAdmin ? (
                    <td className="member_adminstrator hide-on-narrow">
                      {member.administrator ? "■" : "□"}
                    </td>
                  ) : (
                    ""
                  )}{" "}
                  <td className="member_detail">
                    <button
                      className="memberlist-detail-button"
                      onClick={() => handleButtonClick(member.author.id)}
                    >
                      表示
                    </button>
                  </td>
                  {isAdmin ? (
                    <td className="member_delete hide-on-narrow">
                      <button
                        className="memberlist-delete-button"
                        onClick={() =>
                          handleDeleteButtonClick(member.author.id)
                        }
                      >
                        削除
                      </button>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MemberList;
