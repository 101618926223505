import React, { useState } from "react";
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./EmailChange.scss";

const EmailChange = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setMessage("ユーザーがログインしていません。");
      return;
    }

    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    try {
      // ユーザーの再認証
      await reauthenticateWithCredential(user, credential);

      // 新しいメールアドレスの確認メールを送信する
      await verifyBeforeUpdateEmail(user, newEmail);
      setMessage(
        "確認メールを送信しましたので、メール内のリンクをクリックしてください。"
      );
      setTimeout(() => {
        navigate(`/home`);
      }, 5000);
    } catch (error) {
      console.error("エラー:", error);
      if (error.code === "auth/invalid-credential") {
        setMessage("認証失敗です。パスワードを御確認ください。");
      } else {
        setMessage(`エラー: ${error.message}`);
      }
    }
  };

  return (
    <div className="email-adresschange-container">
      <div className="email-adresschange-content">
        <form
          className="email-adresschange-form"
          onSubmit={handleEmailChange}
          aria-labelledby="password-label"
        >
          <div className="email-adresschange-field">
            <p>メールアドレスの変更</p>
          </div>
          <div className="email-adresschange-field">
            <label htmlFor="currentPassword">現在のパスワード</label>
            <input
              className="email-adresschange-input-password"
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="email-adresschange-field">
            <label htmlFor="newEmail">新しいメールアドレス</label>
            <input
              className="email-adresschange-input-password"
              type="email"
              id="newEmail"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              required
            />
          </div>
          <div className="email-adresschange-field">
            <button className="email-adresschange-submit-button" type="submit">
              メールアドレスを変更
            </button>
          </div>
          <div>
            {message && <div className="emailchange-message">{message}</div>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailChange;
