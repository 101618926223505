import { EventContext } from "Contexts/EventContext";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MemberContext } from "../Contexts/MemberContext";
import { auth, db } from "../firebase";
import "./EventCancel.scss";
import { EmailSenderBase } from "../utils/EmailSenderBase";

function EventCancel() {
  const { eventId, eventMemberId } = useParams();
  const navigate = useNavigate();

  const [event, setEvent] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [accountName, setAccountName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const { eventList } = useContext(EventContext);
  const [toEmail, setToEmail] = useState([]);
  const location = useLocation();
  const { cancelstate } = location.state || {};
  const [responseMessage, setResponseMessage] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [membersList, setMembersList] = useState([]);
  //データベースからデータを取得
  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const membersCollection = collection(db, "members");
        const snapshot = await getDocs(membersCollection);
        const membersList = snapshot.docs.map((doc) => {
          const { tel_num, birthday, realname, ...filteredData } = doc.data();
          return { id: doc.id, ...filteredData };
        });

        // membersList をステートにセット
        setMembersList(membersList);
        // administrator が true のメンバーをフィルタリング
        const adminmembers = Object.values(membersList).filter(
          (member) => member?.administrator === true
        );
        const adminEmailString = [
          ...new Set(adminmembers.map((member) => member.email)),
        ].join(", ");

        // フィルタリングしたメールアドレスをセット
        setToEmail(adminEmailString);
      } catch (error) {
        console.error("Error fetching members data:", error);
      }
    };

    fetchMembersData();
  }, []); // 初回レンダリング時に実行

  useEffect(() => {
    const currentEvent = eventList.find((e) => e.id === eventId);
    setEvent(currentEvent);

    const fetchAccountName = async () => {
      try {
        const eventMemberRef = doc(db, "event_members", eventMemberId);
        const eventMemberDoc = await getDoc(eventMemberRef);

        if (eventMemberDoc.exists()) {
          const eventMemberData = eventMemberDoc.data();
          const memberId = eventMemberData.memberId;

          const member = membersList.find((m) => m.author.id === memberId);

          if (member) {
            setAccountName(member.accountname);
            setAccountEmail(member.email);
          } else {
            console.log("No matching member found in 'membersList'");
          }
        } else {
          console.log("No such event member document!");
        }
      } catch (error) {
        console.error("Error fetching account name:", error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchAccountName();
      }
    });

    return () => unsubscribe();
  }, [eventList, membersList, eventId, eventMemberId]);

  if (!currentUser) {
    return <p>Loading...</p>;
  }
  const administratorInfo = membersList.find(
    (member) => member.author.id === currentUser.uid
  );

  const isAdmin = !!administratorInfo.administrator;
  console.log("今のユーザーのアカウント情報" + isAdmin);

  // 本日の日付を取得（開始時間を 00:00:00 にリセット）
  const today = new Date();
  today.setHours(0, 0, 0, 0); // 時刻をリセット

  // イベントの日付を取得
  if (!event) {
    return <p>Loading...</p>;
  }
  const startTime = new Date(event.starttime);
  startTime.setHours(0, 0, 0, 0); // 時刻をリセット
  const daysDifference = (startTime - today) / (1000 * 60 * 60 * 24);
  const twoWeeksFromNow = daysDifference - 14 > 1;

  const handleRemoveParticipant = async () => {
    if (!currentUser) {
      console.error("No current user");
      return;
    }

    if (twoWeeksFromNow || isAdmin) {
      console.log("二週間前以前なのでキャンセル理由は不要です");
    } else {
      if (!cancelText.trim()) {
        alert(
          "他の会員が心配されないようお伝えしたいので理由の記載をよろしくお願いします。"
        );
        return; // 処理を中断
      }
    }

    try {
      const eventMemberRef = doc(db, "event_members", eventMemberId);
      const eventMemberDoc = await getDoc(eventMemberRef);

      if (eventMemberDoc.exists()) {
        const cancelmemberId =
          eventMemberDoc._document.data.value.mapValue.fields.memberId
            .stringValue;

        const cancelmember = membersList.find(
          (m) => m.author.id === cancelmemberId
        );

        const confirmDelete = window.confirm(
          cancelstate
            ? `本当に${format(event.starttime, "M /d（E）HH:mm ", {
                locale: ja,
              })}の${event.title}のイベントをキャンセルされますか？`
            : `本当に${format(event.starttime, "M /d（E）HH:mm ", {
                locale: ja,
              })}の${event.title}のイベントから${
                cancelmember.accountname
              }さんを削除しますか？`
        );

        // ユーザーが「OK」を選択した場合のみ削除を実行

        if (confirmDelete) {
          await deleteDoc(eventMemberRef);
          if (!twoWeeksFromNow) {
            //メールを送付する
            if (cancelstate) {
              const subject = "キャンセルの受付";
              const emailContent = [
                {
                  email: accountEmail,
                  text: `${cancelmember.accountname}さんからの${format(
                    event.starttime,
                    "M /d（E）HH:mm ",
                    { locale: ja }
                  )}の${
                    event.title
                  }のイベントのキャンセル連絡を承りました。\n管理者へはキャンセルがあった旨の連絡メールを別途送付しております。`,
                },
                {
                  email: toEmail,
                  text: `${cancelmember.accountname}さんから${format(
                    event.starttime,
                    "M /d（E）HH:mm ",
                    { locale: ja }
                  )}の${
                    event.title
                  }のイベントのキャンセル連絡がありました。こちらの情報をご確認ください。\n\nキャンセル理由:\n\n${cancelText}
                \n\nhttps://raising2024-7e234.web.app/eventdetail/${eventId}`,
                },
              ];
              emailContent.forEach(({ email, text }) => {
                EmailSenderBase([email], subject, text, setResponseMessage); // それぞれに個別に送信
              });
              alert("キャンセル連絡のメールが送信されました。");
            }
          } else {
            console.log("二週間以前のキャンセルなのでメールを送付しない");
          }

          navigate(`/eventdetail/${eventId}`);
        } else {
          console.log("削除操作はキャンセルされました。");
          navigate(`/eventdetail/${eventId}`);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error(
        "イベントからのユーザーの削除中にエラーが発生しました:",
        error
      );
    }
  };

  // 入力変更ハンドラー
  const handleChange = (e) => {
    const { value } = e.target;
    setCancelText(value);
  };

  return (
    <div className="eventcancel-container">
      <div className="eventcancel-content">
        <div className="eventcancel-field">
          <h2>参加取消画面</h2>
          <div
            className="cancel-close-button"
            onClick={() => navigate(`/eventdetail/${eventId}`)}
          >
            X
          </div>
        </div>
        <div className="eventcancel-field">
          <div className="eventcancel-field-child">
            <label>タイトル </label>
            <div className="cancel-event">{event.title}</div>
          </div>
          <div className="eventcancel-field-child2">
            <div className="eventcancel-field-child3">
              <label>開催日時 </label>
              <div className="cancel-event">
                {" "}
                {format(event.starttime, "M /d（E）HH:mm ", {
                  locale: ja,
                })}
              </div>
            </div>
            <div className="eventcancel-field-child3">
              <label>会員名</label>
              <div className="cancel-event">
                {accountName ? accountName : "検索中"}
              </div>
            </div>
          </div>{" "}
          {twoWeeksFromNow || isAdmin ? null : (
            <div className="eventcancel-field-child">
              <label>キャンセルの理由</label>
              <p>
                （他の会員が心配されないようお伝えしたいのでお願いします。）
              </p>
              <div className="cancel-text">
                <textarea
                  name="cancel-text"
                  value={cancelText}
                  onChange={handleChange}
                  type=""
                  required
                ></textarea>
              </div>
            </div>
          )}
        </div>
        <div className="eventcancel-field">
          <div className="eventcancel-button">
            <button onClick={handleRemoveParticipant}>キャンセル実行</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCancel;
