// src/components/EmailComponents/RegistEmailSender.js
// src/components/EmailComponents/RegistEmailSender.js

const sendRegistrationEmail = async (emailPayload) => {
  try {
    if (!emailPayload.to || !emailPayload.subject || !emailPayload.text) {
      throw new Error("メール送信に必要な情報が不足しています。");
    }
    const response = await fetch(
      "https://us-central1-raising2024-7e234.cloudfunctions.net/sendEmail",
      // "http://127.0.0.1:5001/raising2024-7e234/us-central1/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      }
    );

    if (!response.ok) {
      throw new Error("メール送信に失敗しました");
    }

    const data = await response.json();
    console.log("メール送信成功:", data.message);
  } catch (error) {
    console.error("メール送信エラー:", error);
    alert("メール送信中にエラーが発生しました。");
  }
};

// カスタムメール送信関数も必要に応じて追加
const sendCustomEmail = async (to, subject, text) => {
  const emailPayload = { to, subject, text };

  try {
    const response = await fetch(
      "https://us-central1-raising2024-7e234.cloudfunctions.net/sendEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      }
    );

    if (!response.ok) {
      throw new Error("メール送信に失敗しました");
    }

    const data = await response.json();
    console.log("カスタムメール送信成功:", data.message);
  } catch (error) {
    console.error("カスタムメール送信エラー:", error);
    alert("カスタムメール送信中にエラーが発生しました。");
  }
};

// RegistEmailSenderという関数を作成し、デフォルトエクスポート
// const RegistEmailSender = () => {
//   return (
//     <div>
//       <h1>登録メール送信</h1>
//       {/* 必要なUIをここに追加 */}
//     </div>
//   );
// };

// 必要な関数をエクスポート
export { sendRegistrationEmail, sendCustomEmail };
// export default RegistEmailSender;
